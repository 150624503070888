import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { DatabaseService } from './database.service';
import { AnalyticsService } from './analytics.service';
import { authState } from '@angular/fire/auth';
import { getAuth } from 'firebase/auth';
import { IUser } from '../models/database.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public uid = new BehaviorSubject('');
  public activeOrganization = new BehaviorSubject('');
  public isAdmin = new BehaviorSubject(false);
  public userName = new BehaviorSubject('');
  public userLogoUrl = new BehaviorSubject('');
  public token = new BehaviorSubject('');
  public userData = new BehaviorSubject({
    uid: '',
    isAdmin: false,
  });
  public organizationData = new BehaviorSubject({
    name: '',
    logoUrl: '',
  });

  constructor(
    public authService: AuthService,
    private databaseService: DatabaseService,
    private analyticsService: AnalyticsService
  ) {
    this.subscribeToAuthState();
    this.uid.subscribe((uid) => {
      if (uid) {
        this.impersonateUser(uid);
      }
    });
    this.activeOrganization.subscribe((organization) => {
      if (organization) {
        this.updateOrgData(organization);
      }
    });
  }

  subscribeToAuthState() {
    const auth = getAuth();
    authState(auth).subscribe((user) => {
      const uid = user?.uid || '';
      this.uid.next(uid);
      if (uid) {
        this.updateUserData(uid);
      } else {
        this.userData.next({
          uid: '',
          isAdmin: false,
        });
      }
    });
  }

  updateCommonUserData(user: IUser) {
    this.activeOrganization.next(user.organizations[0]);
    this.userData.next({
      uid: this.uid.value,
      isAdmin: this.isAdmin.value,
    });
  }

  impersonateUser(uid: string) {
    this.databaseService.getUserDocument(uid).subscribe((user) => {
      this.updateCommonUserData(user);
    });
  }
  updateUserData(uid: string) {
    this.databaseService.getUserDocument(uid).subscribe((user) => {
      this.updateCommonUserData(user);
      this.isAdmin.next(user.isAdmin || false);
      this.analyticsService.setAnalitics(user.email);
    });
  }

  updateOrgData(orgId: string) {
    this.databaseService.getOrganizationDocument(orgId).subscribe((org) => {
      this.organizationData.next({
        name: org.name,
        logoUrl: org.logoUrl,
      });
    });
  }

  isLoggedIn() {
    return Boolean(this.uid.value);
  }
}

<section class="vh-100">
  <div class="container-fluid h-custom">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
        <form>
          <p class="lead fw-normal mb-0 me-3 text-blue-finapsys">
            Please enter your email address to request a password reset.
          </p>
          <br />
          <!-- Email input -->
          <div class="form-outline mb-4">
            <input
              type="email"
              id="form3Example3"
              class="form-control form-control-lg"
              placeholder="Enter a valid email address"
              #passwordResetEmail
              required
            />
          </div>

          <div class="text-center text-lg-start mt-4 pt-2">
            <button
              type="button"
              class="btn btn-primary btn-lg"
              style="padding-left: 2.5rem; padding-right: 2.5rem"
              (click)="authService.ForgotPassword(passwordResetEmail.value)"
            >
              Reset Password
            </button>
          </div>
        </form>
        <div class="redirectToLogi text-gray">
          <span
            >Go back to
            <span class="redirect" routerLink="/sign-in">Log In</span></span
          >
        </div>
      </div>
    </div>
  </div>
</section>

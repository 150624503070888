const authErrors: { [key: string]: string; } = {
  'auth/user-not-found': 'Email o Contraseña incorrectos.',
  'auth/wrong-password': 'Email o Contraseña incorrectos.',
  'auth/invalid-email': 'Email o Contraseña incorrectos.',
  'auth/too-many-requests': 'Demasiados intentos, intenta nuevamente en unos minutos.',
  'auth/user-disabled': 'Tu cuenta de finapsys no esta activa. Comunícate con nosotros en soporte@finapsys.cl para activarla',
  'reset-password-auth/user-not-found': 'Si el email ingresado corresponde a un usuario registrado en nuestra plataforma, te debería llegar un correo para generar una nueva contraseña',
  'reset-password-auth/invalid-email': 'Si el email ingresado corresponde a un usuario registrado en nuestra plataforma, te debería llegar un correo para generar una nueva contraseña',
  'reset-password-auth/too-many-requests': 'Demasiados intentos, intenta nuevamente en unos minutos.',
  'auth/admin-restricted-operation': 'Email o Contraseña incorrectos.',
  'auth/invalid-verification-code': 'Código de verificación incorrecto, intenta nuevamente.',
  'google-auth/internal-error': 'Habla con el administrador de finapsys en tu organizacion si el problema persiste.',
  'google-auth/user-disabled': 'Tu cuenta de finapsys no esta activa. Comunícate con nosotros en soporte@finapsys.cl para activarla',
  'default': 'Intente nuevamente en unos minutos.',
}

export { authErrors };

import { Injectable, OnChanges } from '@angular/core';
import { UserService } from './user.service';
import { FunctionsService } from './functions.service';
import { IUserOrganizations } from '../components/bonos/bonos.component';
import {
  IDashboardData,
  IDataSet,
} from '../components/dashboard/dashboard.component';
import { DatabaseService } from './database.service';
import { BehaviorSubject, map, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  public isDashboardSet = false;
  private emptyDataSet: IDataSet = {
    name: '',
    dataX: [],
    dataY: [],
  };
  public dashBoardData: IDashboardData = {
    infoCardsData: {
      reimbursementAmount: 0,
      bondPercentage: '',
      bondsToDownload: 0,
      payorWithMoreBonds: '',
    },
    dataSets: {
      dataSetbondQuantityInTime: this.emptyDataSet,
      dataSetBondPercentajePerPayor: this.emptyDataSet,
      dataSetbondsAmountsInTime: this.emptyDataSet,
      dataSetPayorsBondCount: this.emptyDataSet,

      dataSetPayorsReimbursementInTime: this.emptyDataSet,
      dataSetBondsQuantityAndAverageAmountByWeek: this.emptyDataSet,
      dataSetReimbursementAmountAndPercentageByWeek: this.emptyDataSet,
      dataSetGetBondCountPerPayorPerDate: this.emptyDataSet,
    },
  };

  private dashBoardDataSubject: BehaviorSubject<IDashboardData> =
    new BehaviorSubject<IDashboardData>(this.dashBoardData);
  public dashBoardData$ = this.dashBoardDataSubject.asObservable();

  constructor(
    public userService: UserService,
    public databaseService: DatabaseService,
    public functionService: FunctionsService
  ) {
    this.userService.activeOrganization.subscribe((organization) => {
      this.setDashboardData();
    });
  }

  public setDashboardData() {
    return this.getUserInformation()
      .pipe(
        switchMap(() => {
          return this.functionService.callDashboardData(
            this.userService.uid.getValue(),
            this.userService.activeOrganization.getValue(),
            this.userService.token.getValue() ?? undefined
          );
        })
      )
      .subscribe((dashBoardData: IDashboardData) => {
        this.isDashboardSet = true;
        this.dashBoardDataSubject.next(dashBoardData);
      });
  }

  getUserInformation() {
    return this.databaseService
      .getUserDocument(this.userService.uid.getValue())
      .pipe(
        map((user): IUserOrganizations => {
          return {
            name: user.email,
            uid: user.id || '',
            organizations: user.organizations,
          };
        })
      );
  }
}

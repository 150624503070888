import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { DatabaseService } from 'src/app/services/database.service';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';

interface IOrganization {
  name: string;
  domains: string[];
  organizationId: string;
}

interface IUserOrganizations {
  uid: string;
  name: string;
  organizations: string[];
}
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent {
  public userAdmin = false;
  public organizationAdmin = false;
  public bonosView: boolean;
  public adminView: boolean;
  public dashBoardView: boolean;
  public denialsView: boolean;
  public followUpView: boolean;
  public fonasaDashboardView: boolean;
  public fonasaDailyCasesView: boolean;
  public getFonasaPaymentGroupsDashboard: boolean;
  public getFonasaPaymentGroupsDailyChanges: boolean;
  public claimsStockView: boolean;
  currentEnvironment = environment.name;
  currentVersion = environment.version;
  activeOrganization = '';
  activeOrganizationName = '';
  uid = '';
  userOrganizations: IOrganization[] = [];
  showFonasaModule = false;
  showDenialsModule = false;
  showFonasaSubitems = false;
  showPayorsSubitems = false;
  showClaimsStockModule = false;
  showFollowUpModule = false;

  constructor(
    public userService: UserService,
    private databaseService: DatabaseService
  ) {
    this.userService.uid.subscribe((uid) => {
      this.uid = uid;
      this.getOrganizationsOfUser();
    });
    this.userService.isAdmin.subscribe((isAdmin) => (this.userAdmin = isAdmin));
    this.userService.activeOrganization.subscribe((organization) => {
      this.activeOrganization = organization;
    });
    this.bonosView = false;
    this.adminView = false;
    this.dashBoardView = true;
    this.denialsView = false;
    this.followUpView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.claimsStockView = false;
  }

  getOrganizationsOfUser() {
    this.getUserInformation().subscribe((user) => {
      this.userOrganizations = [];
      user.organizations.map((organization) => {
        this.databaseService
          .getOrganizationDocument(organization)
          .subscribe((organization) => {
            this.userOrganizations.push({
              name: organization.name,
              domains: organization.domains,
              organizationId: String(organization.id),
            });
            if (organization.id === this.activeOrganization) {
              this.activeOrganizationName = organization.name;
              this.databaseService
                .getOrganizationSettingsDocument(organization.id)
                .subscribe((organizationSettings) => {
                  this.showFonasaModule =
                    organizationSettings.products.fonasa.showInSideNav;
                  this.showDenialsModule =
                    organizationSettings.products.denials.showInSideNav;
                  this.showClaimsStockModule =
                    organizationSettings.products.claimsStock.showInSideNav;
                  this.showFollowUpModule =
                    organizationSettings.products.followup.showInSideNav;
                });
            }
            if (organization.domains.includes('finapsys.cl')) {
              this.organizationAdmin = true;
            }
          });
      });
    });
  }

  changeOrganization(organization: IOrganization) {
    this.userService.activeOrganization.next(organization.organizationId);
    this.activeOrganizationName = organization.name;
  }

  getUserInformation() {
    return this.databaseService.getUserDocument(this.uid).pipe(
      map((user): IUserOrganizations => {
        return {
          name: user.email,
          uid: user.id || '',
          organizations: user.organizations,
        };
      })
    );
  }
  loadDashboard() {
    this.dashBoardView = true;
    this.bonosView = false;
    this.adminView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.denialsView = false;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.claimsStockView = false;
    this.followUpView = false;
  }

  loadBonos() {
    this.dashBoardView = false;
    this.bonosView = true;
    this.adminView = false;
    this.denialsView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.claimsStockView = false;
    this.followUpView = false;
  }

  loadAdmin() {
    this.dashBoardView = false;
    this.bonosView = false;
    this.adminView = true;
    this.denialsView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.followUpView = false;
  }

  loadFonasaDailyCases() {
    this.dashBoardView = false;
    this.bonosView = false;
    this.adminView = false;
    this.denialsView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = true;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.claimsStockView = false;
    this.followUpView = false;
  }

  loadDenials() {
    this.dashBoardView = false;
    this.bonosView = false;
    this.adminView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.denialsView = true;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.claimsStockView = false;
    this.followUpView = false;
  }

  loadFonasaDashboard() {
    this.dashBoardView = false;
    this.bonosView = false;
    this.adminView = false;
    this.fonasaDashboardView = true;
    this.fonasaDailyCasesView = false;
    this.denialsView = false;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.claimsStockView = false;
    this.followUpView = false;
  }

  loadFonasaPaymentGroupsDashboard() {
    this.dashBoardView = false;
    this.bonosView = false;
    this.adminView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.denialsView = false;
    this.getFonasaPaymentGroupsDashboard = true;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.claimsStockView = false;
    this.followUpView = false;
  }

  loadFonasaPaymentGroupsDailyChanges() {
    this.dashBoardView = false;
    this.bonosView = false;
    this.adminView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.denialsView = false;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = true;
    this.claimsStockView = false;
    this.followUpView = false;
  }

  loadClaimsStock() {
    this.dashBoardView = false;
    this.bonosView = false;
    this.adminView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.denialsView = false;
    this.getFonasaPaymentGroupsDashboard = false;
    this.getFonasaPaymentGroupsDailyChanges = false;
    this.claimsStockView = true;
    this.followUpView = false;
  }

  loadFollowUp() {
    this.dashBoardView = false;
    this.bonosView = false;
    this.adminView = false;
    this.denialsView = false;
    this.fonasaDashboardView = false;
    this.fonasaDailyCasesView = false;
    this.claimsStockView = false;
    this.followUpView = true;
  }
}

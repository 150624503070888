import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
})
export class TopNavComponent {
  orgName = '';
  orgLogoUrl = '';
  uid = '';

  constructor(
    public authService: AuthService,
    public userService: UserService
  ) {
    this.userService.userData.subscribe((userData) => {
      this.uid = userData.uid;
    });
    this.userService.organizationData.subscribe((organizationData) => {
      this.orgName = organizationData.name;
      this.orgLogoUrl = organizationData.logoUrl;
    });
  }

  async signOut() {
    window.location.reload();
    await this.authService.SignOut();
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, take, filter } from 'rxjs';
import {
  IDailyReports,
  IExecutionLog,
  IMockConfigs,
  IOrganization,
  IUser,
  IOrganizationSettings,
} from '../models/database.model';

@Injectable({
  providedIn: 'root',
})
export class DatabaseService {
  dailyreports: IDailyReports[] = [];
  users: IUser[] = [];
  organizations: IOrganization[] = [];
  constructor(private afsCompact: AngularFirestore) {}

  getExecutionLogs(quantity: number) {
    if (!quantity) throw new Error('No quantity');
    const result = this.afsCompact
      .collection('execution-logs', (ref) =>
        ref.orderBy('date', 'desc').limit(quantity)
      )
      .valueChanges({ idField: 'id' });
    return result as Observable<IExecutionLog[]>;
  }

  getUserDocument(uid: string) {
    if (!uid) throw new Error('No uid');
    const result = this.afsCompact
      .collection('users')
      .doc(uid)
      .valueChanges({ idField: 'id' })
      .pipe(
        filter((user) => (user as IUser).organizations?.length > 0),
        take(1)
      );
    return result as Observable<IUser>;
  }

  getOrganizationDocument(id: string) {
    if (!id) throw new Error('No id');
    const result = this.afsCompact
      .collection('organizations')
      .doc(id)
      .valueChanges({ idField: 'id' })
      .pipe(take(1));
    return result as Observable<IOrganization>;
  }

  getOrganizationSettingsDocument(id: string) {
    if (!id) throw new Error('No id');
    const result = this.afsCompact
      .collection('settings')
      .doc('organizations')
      .collection('organization-settings')
      .doc(id)
      .valueChanges({ idField: 'id' })
      .pipe(take(1));
    return result as Observable<IOrganizationSettings>;
  }

  getAllUsers() {
    const result = this.afsCompact
      .collection('users')
      .valueChanges({ idField: 'id' })
      .pipe(take(1));
    return result as Observable<IUser[]>;
  }

  getAllOrganizations() {
    const result = this.afsCompact
      .collection('organizations')
      .valueChanges({ idField: 'id' });
    return result as Observable<IOrganization[]>;
  }

  updateDailyReportsDownloaded(docID: string, downloadedAt: string[]) {
    if (!docID) throw new Error('No docID');
    this.afsCompact.doc(`reports/${docID}`).update({ downloadedAt });
  }

  getAllMockConfigs() {
    const result = this.afsCompact
      .collection('mock-configs')
      .valueChanges({ idField: 'id' })
      .pipe(take(1));
    return result as Observable<IMockConfigs[]>;
  }

  updateMockConfigs(
    docID: string,
    throwError500: boolean,
    timeOutError: boolean,
    bondsNotFound: boolean,
    waitingForDownload: boolean
  ) {
    if (!docID) throw new Error('No docID');
    this.afsCompact.doc(`mock-configs/${docID}`).update({ throwError500 });
    this.afsCompact.doc(`mock-configs/${docID}`).update({ timeOutError });
    this.afsCompact.doc(`mock-configs/${docID}`).update({ bondsNotFound });
    this.afsCompact.doc(`mock-configs/${docID}`).update({ waitingForDownload });
  }
}

import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';

export interface IDataSet {
  name: string;
  dataX: string[];
  dataY: string[] | number[] | string[][];
  dataY2?: string[] | number[] | number[][] | string[][];
}

export interface IDatasets {
  dataSetbondQuantityInTime: IDataSet;
  dataSetBondPercentajePerPayor: IDataSet;
  dataSetbondsAmountsInTime: IDataSet;
  dataSetPayorsBondCount: IDataSet;
  dataSetPayorsReimbursementInTime: IDataSet;
  dataSetBondsQuantityAndAverageAmountByWeek: IDataSet;
  dataSetReimbursementAmountAndPercentageByWeek: IDataSet;
  dataSetGetBondCountPerPayorPerDate: IDataSet;
}

export interface ICardsInfo {
  bondsToDownload: number;
  bondPercentage: string;
  reimbursementAmount: number;
  payorWithMoreBonds: string;
}

export interface IDashboardData {
  dataSets: IDatasets;
  infoCardsData: ICardsInfo;
}
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  constructor(public dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.dashboardService.setDashboardData();
  }
}

<div class="container-fluid">
  <div class="header-body">
    <div class="row">
      <div class="col-xl-3 col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Cuentas Disponibles
                </h5>
                <div class="h7 card-title text-uppercase text-muted mb-0">
                  Aún no descargados
                </div>
                <span class="h4 font-weight-bold mb-0">
                  {{ (dashBoardData$ | async)?.infoCardsData?.bondsToDownload }}
                  Cuentas
                </span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-gradient-red text-white rounded-circle shadow"
                >
                  <i class="ni ni-cloud-download-95"></i>
                </div>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm"></p>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Monto Bonificado
                </h5>
                <div class="h7 card-title text-uppercase text-muted mb-0">
                  Últimos 7 días
                </div>
                <span class="h4 font-weight-bold mb-0"
                  >${{
                    (dashBoardData$ | async)?.infoCardsData?.reimbursementAmount
                  }}
                  millones</span
                >
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-gradient-orange text-white rounded-circle shadow"
                >
                  <i class="ni ni-money-coins"></i>
                </div>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm"></p>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Porcentaje de Bonificación
                </h5>
                <div class="h7 card-title text-uppercase text-muted mb-0">
                  Últimos 7 días
                </div>
                <span class="h4 font-weight-bold mb-0"
                  >{{
                    (dashBoardData$ | async)?.infoCardsData?.bondPercentage
                  }}%</span
                >
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-gradient-green text-white rounded-circle shadow"
                >
                  <i class="ni ni-money-coins"></i>
                </div>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm"></p>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-stats">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title text-uppercase text-muted mb-0">
                  Isapre con más cuentas
                </h5>
                <div class="h7 card-title text-uppercase text-muted mb-0">
                  Últimos 7 días
                </div>
                <span class="h4 font-weight-bold mb-0">{{
                  (dashBoardData$ | async)?.infoCardsData?.payorWithMoreBonds
                }}</span>
              </div>
              <div class="col-auto">
                <div
                  class="icon icon-shape bg-gradient-info text-white rounded-circle shadow"
                >
                  <i class="ni ni-paper-diploma"></i>
                </div>
              </div>
            </div>
            <p class="mt-3 mb-0 text-sm"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

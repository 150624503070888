<div>
  <div class="pb-8 header bg-blue-light-finapsys">
    <div class="container-fluid">
      <div class="header-body">
        <div class="py-4 row align-items-center">
          <div class="col-lg-6 col-7">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-0">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a class="h2 nav-link-text">Fonasa: Cambios de estado diarios</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt--8">
  <div class="main-content" id="panel">
    <!-- Topnav -->

    <!-- Page content -->
    <div class="container-fluid mt--6">
      <div class="row">
        <!-- Light table -->
        <div class="col">
          <div class="card">
            <!-- Card header -->
            <div class="border-0 card-header">
              <h3 *ngIf="showFonasaModule === true" class="mb-0">Reportes diarios</h3>
              <p *ngIf="showFonasaModule !== true" class="mb-0 text-gray">Tu organización no tiene activo el modulo de fonasa. Pidele al administrador de tu organización que se contacte con el equipo de Finapsys para hacerlo.</p>
            </div>
            <!-- Light table -->
            <div *ngIf="showFonasaModule === true" class="table-responsive" data-toggle="list"
              data-list-values='["Fecha", "N°Casos", "Reporte"]'>
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th scope="col" class="text-center sort">
                      <h6 class="text-muted">Fecha</h6>
                    </th>
                    <th scope="col" class="text-center sort">
                      <h6 class="text-muted">Número de Casos</h6>
                    </th>
                    <th scope="col" class="text-center sort">
                      <h6 class="text-muted">Reporte de casos</h6>
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="loading">
                  <tr>
                    <td colspan="6" class="text-center">
                      <div class="spinner-border" role="status">
                        <img src="../../../assets/ball-triangle.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <ng-container *ngFor="let item of dailyreports">
                  <tbody class="list">
                    <tr>
                      <td class="text-center Fecha">
                        {{ item.reportDate }}
                      </td>
                      <td class="N°Casos text-center">{{ item.numberOfCases }}</td>
                      <td class="text-center">
                        <a type="button"  *ngIf="item.newCasesReportPath">
                          <button (click)="downloadFonasaReport(item)" type="button" class="btn btn-outline">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" fill="green"
                              class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                              <path
                                d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z">
                              </path>
                              <path
                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z">
                              </path>
                            </svg>
                          </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </ng-container>
              </table>
            </div>
            <!-- Card footer -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

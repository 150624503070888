import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Maind Health';

  constructor(
    public userService: UserService,
    public authService: AuthService
  ) {}
}

import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import { FunctionsService } from 'src/app/services/functions.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { formatNumberToMillionsClp } from '../constants';
import { DatabaseService } from 'src/app/services/database.service';

type IFonasaDailyPaymentsDashboardData = {
  dataSets: {
    urgencyLaw: {
      name: string;
      dataX: string[];
      dataY: number[];
      dataY2: number[];
    };
    secondProvider: {
      name: string;
      dataX: string[];
      dataY: number[];
      dataY2: number[];
    };
    grd: {
      name: string;
      dataX: string[];
      dataY: number[];
      dataY2: number[];
    };
  };
};

@Component({
  selector: 'app-fonasa-daily-payments-dashboard',
  templateUrl: './fonasa-daily-payments-dashboard.component.html',
  styleUrls: ['./fonasa-daily-payments-dashboard.component.scss'],
})
export class FonasaDailyPaymentsDashboardComponent {
  showFonasaModule = true;
  activeOrganization = '';
  uid = '';
  loading = false;
  loadingDownload = false;
  token = '';
  showUrgencyLaw = false;
  showSecondProvider = false;
  showGrd = false;
  constructor(
    private userService: UserService,
    private functionService: FunctionsService,
    private analyticsService: AnalyticsService,
    private databaseService: DatabaseService
  ) {
    this.userService.uid.subscribe((uid) => (this.uid = uid));
    this.userService.token.subscribe((token) => (this.token = token));
    this.userService.activeOrganization.subscribe((organization) => {
      this.activeOrganization = organization;
      this.databaseService.getOrganizationSettingsDocument(this.activeOrganization).subscribe((settings) => {
        this.showFonasaModule = settings.products.fonasa.showModule ?? false;
        this.showUrgencyLaw = settings.products.fonasa.enableUrgencyLaw ?? false;
        this.showSecondProvider = settings.products.fonasa.enableSecondProvider ?? false;
        this.showGrd = settings.products.fonasa.enableGrd ?? false;
      });
    });
  }

  ngOnInit(): void {
    this.showFonasaDailyPaymentsDashboardChart();
  }

  downloadFonasaDatabase() {
    this.analyticsService.logEvent(
      this.analyticsService.events.download_fonasa_database,
      {
        type: 'fonasa-database',
      }
    );
    this.loadingDownload = true;
    if (!this.uid) throw new Error('No uid');
    this.functionService
      .callFonasaPaymentGroupsDownloadDatabase(
        this.uid,
        this.activeOrganization,
        this.token
      )
      .subscribe((url: string) => {
        this.loadingDownload = false;
        window.open(url);
      });
  }

  showFonasaDailyPaymentsDashboardChart() {
    this.loading = true;
    if (!this.uid) throw new Error('No uid');
    Chart.defaults.color = 'white';
    this.functionService
      .callFonasaDailyPaymentsDashboardData(
        this.uid,
        this.activeOrganization,
        this.token
      )
      .subscribe((fonasaDashboardData: IFonasaDailyPaymentsDashboardData) => {
        this.loading = false;
        if (!(fonasaDashboardData.dataSets.urgencyLaw.dataY2.reduce((a, b) => a + b, 0) === 0)) {
          this.showPaymentGroupsDasboardChart(fonasaDashboardData, 'fonasaPaymentGroupsUrgencyLawDashboardChart', 'urgencyLaw');
          this.showUrgencyLaw = true;
        }
        if (!(fonasaDashboardData.dataSets.secondProvider.dataY2.reduce((a, b) => a + b, 0) === 0)) {
          this.showPaymentGroupsDasboardChart(fonasaDashboardData, 'fonasaPaymentGroupsSecondProviderDashboardChart', 'secondProvider');
          this.showSecondProvider = true;
        }
        if (!(fonasaDashboardData.dataSets.grd.dataY2.reduce((a, b) => a + b, 0) === 0)) {
          this.showPaymentGroupsDasboardChart(fonasaDashboardData, 'fonasaPaymentGroupsGrdDashboardChart', 'grd');
          this.showGrd = true;
        }
      });
  }

  showPaymentGroupsDasboardChart(fonasaDashboardData: IFonasaDailyPaymentsDashboardData, chartName: string, dataset: 'urgencyLaw' | 'secondProvider' | 'grd') {
    const fonasaDashboardChart = Chart.getChart(chartName);
    Chart.register(...registerables);
    const chart = new Chart(chartName, {
      type: 'bar',
      data: {
        labels: fonasaDashboardData.dataSets[dataset].dataX,
        datasets: [
          {
            label: 'Cantidad de grupos de pago',
            data: fonasaDashboardData.dataSets[dataset].dataY2,
            borderWidth: 2,
            borderRadius: 1,
            yAxisID: 'y1',
            datalabels: {
              align: 'start',
              anchor: 'end',
            },
          },
          {
            label: 'Monto total ($CLP millones)',
            data: fonasaDashboardData.dataSets[dataset].dataY,
            borderWidth: 2,
            type: 'line',
            yAxisID: 'y2',
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: 'rgba(236,73,104,255)',
            },
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                const yAxisID = context.dataset.yAxisID;
                if (yAxisID === 'y2')
                  return (
                    label + ': ' + formatNumberToMillionsClp(context.parsed.y, 1)
                  );
                return label + ': ' + context.parsed.y;
              },
            },
          },
        },
        indexAxis: 'x',
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y1: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              color: 'rgba(236,73,104,255)',
              callback: function (value, _, __) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }
}

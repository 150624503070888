<div>
  <div class="pb-8 header bg-blue-light-finapsys">
    <div class="container-fluid">
      <div class="header-body">
        <div class="py-4 row align-items-center">
          <div class="col-lg-6 col-7">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-0">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a class="h2 nav-link-text">Bonos de Isapres</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt--8">
  <div class="main-content" id="panel">
    <!-- Topnav -->

    <!-- Page content -->
    <div class="container-fluid mt--6">
      <div class="row">
        <!-- Light table -->
        <div class="col">
          <div class="card">
            <!-- Card header -->
            <div class="border-0 card-header">
              <h3 class="mb-0">Reportes de Bonos Diarios</h3>
            </div>
            <!-- Light table -->
            <div class="table-responsive" data-toggle="list"
              data-list-values='["Fecha", "N°Bonos", "ZipDescargado?", "Zip", "Reporte"]'>
              <table class="table align-items-center table-flush">
                <thead class="thead-light">
                  <tr>
                    <th scope="col" class="text-center sort">
                      <h6 class="text-muted">Fecha</h6>
                    </th>
                    <th scope="col" class="text-center sort">
                      <h6 class="text-muted">Número de Cuentas</h6>
                    </th>
                    <th scope="col" class="text-center sort">
                      <h6 class="text-muted">Descargado?</h6>
                    </th>
                    <th scope="col" class="text-center sort">
                      <h6 class="text-muted">Carpetas de Bonos</h6>
                    </th>
                    <th scope="col" class="text-center sort">
                      <h6 class="text-muted">Resumen de Bonos</h6>
                    </th>
                    <th scope="col" class="text-center sort" *ngIf="isAdmin || showColumnDetailedInformation === true">
                      <h6 class="text-muted">Detalle de Bonos {{ isAdmin ? '(Admin)' : ''}}</h6>
                    </th>
                  </tr>
                </thead>
                <tbody *ngIf="loading">
                  <tr>
                    <td colspan="6" class="text-center">
                      <div class="spinner-border" role="status">
                        <img src="../../../assets/ball-triangle.svg" />
                      </div>
                    </td>
                  </tr>
                </tbody>
                <ng-container *ngFor="let item of dailyreports">
                  <tbody class="list">
                    <tr>
                      <td class="text-center Fecha">
                        {{ item.dateCount && (item.dateCount > 1) ? item.reportDate + ' (' + item.dateCount + ')' :
                        item.reportDate }}
                      </td>
                      <td class="N°Bonos text-center">{{ item.quantity }}</td>
                      <td *ngIf="item.downloadedAt.length" class="ZipDescargado? text-center">
                        <img src="assets/img/icons/bonds/square-check-regular.svg" alt="" width="20" height="20" />
                      </td>

                      <td class="ZipDescargado? text-center" *ngIf="!item.downloadedAt.length && item.quantity != '0'">
                        <img src="assets/img/icons/bonds/square-regular.svg" alt="" width="20" height="20" />
                      </td>

                      <td class="text-center">
                        <a (click)="downloadZip(item)" type="button " *ngIf="item.quantity != '0'">
                          <button type="button" class="btn btn-outline">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" fill="#ff7b00"
                              class="bi bi-file-zip" viewBox="0 0 16 16">
                              <path
                                d="M6.5 7.5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v.938l.4 1.599a1 1 0 0 1-.416 1.074l-.93.62a1 1 0 0 1-1.109 0l-.93-.62a1 1 0 0 1-.415-1.074l.4-1.599V7.5zm2 0h-1v.938a1 1 0 0 1-.03.243l-.4 1.598.93.62.93-.62-.4-1.598a1 1 0 0 1-.03-.243V7.5z">
                              </path>
                              <path
                                d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm5.5-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9v1H8v1h1v1H8v1h1v1H7.5V5h-1V4h1V3h-1V2h1V1z">
                              </path>
                            </svg>
                          </button>
                        </a>
                      </td>
                      <td class="text-center">
                        <a (click)="downloadResumen(item)" type="button" *ngIf="item.quantity != '0'">
                          <button type="button" class="btn btn-outline">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" fill="green"
                              class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                              <path
                                d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z">
                              </path>
                              <path
                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z">
                              </path>
                            </svg>
                          </button>
                        </a>
                      </td>
                      <td class="text-center"
                        *ngIf="(isAdmin || showColumnDetailedInformation === true) && item.quantity != '0' && item.bondsExtractedDataCsvPath">
                        <a (click)="downloadBondsDataExtracted(item)" type="button">
                          <button type="button" class="btn btn-outline">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" fill="green"
                              class="bi bi-file-earmark-excel" viewBox="0 0 16 16">
                              <path
                                d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z">
                              </path>
                              <path
                                d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z">
                              </path>
                            </svg>
                          </button>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </ng-container>
              </table>
            </div>
            <!-- Card footer -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
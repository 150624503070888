import { Component, OnInit } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { IOrganization } from 'src/app/models/database.model';
import { IUserOrganizations } from '../bonos/bonos.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { map } from 'rxjs';
import { DateTime } from 'luxon';

interface ReportTimeStamp{
  _seconds: number;
  _nanoseconds: number;
}

type IFonasaReportItem = {
  id: string;
  owner: string;
  numberOfCases: number;
  downloadedAt: string[];
  newCasesReportPath: string;
  reportDate: ReportTimeStamp;
}

@Component({
  selector: 'app-fonasa-daily-cases',
  templateUrl: './fonasa-daily-cases.component.html',
  styleUrls: ['./fonasa-daily-cases.component.scss']
})
export class FonasaDailyCasesComponent {
  dailyreports: IFonasaReportItem[] = [];
  loading = false;
  activeOrganization = '';
  currentOrganization = {} as IOrganization;
  uid = '';
  organizationId = '';
  token = '';
  showFonasaModule = false;
  constructor(
    private databaseService: DatabaseService,
    public userService: UserService,
    private functionsService: FunctionsService,
    private analyticsService: AnalyticsService,
  ) {
    this.userService.uid.subscribe((uid) => (this.uid = uid));
    this.userService.activeOrganization.subscribe((organization) => {
      this.activeOrganization = organization;
      this.setOrganizationsOfLoggedUserAndGetBonosDailyReports();
      this.databaseService.getOrganizationSettingsDocument(this.activeOrganization).subscribe((settings) => {
        this.showFonasaModule = settings.products.fonasa.showModule;
      });
    });
    this.userService.token.subscribe((token) => (this.token = token));
  }

  ngOnInit(): void {
    this.setOrganizationsOfLoggedUserAndGetBonosDailyReports();
  }

  setOrganizationsOfLoggedUserAndGetBonosDailyReports() {
    this.loading = true;
    this.getUserInformationForReports().subscribe((user) => {
      this.getBonosDailyReports();
      this.databaseService
        .getOrganizationDocument(this.activeOrganization)
        .subscribe((organization) => {
          this.currentOrganization = organization;
          this.databaseService.getOrganizationSettingsDocument(this.activeOrganization).subscribe((settings) => {
            this.showFonasaModule = settings.products.fonasa.showModule;
          });
        });
    });
  }

  getUserInformationForReports() {
    return this.databaseService.getUserDocument(this.uid).pipe(
      map((user): IUserOrganizations => {
        return {
          name: user.email,
          uid: user.id || '',
          organizations: user.organizations,
        };
      })
    );
  }

  getBonosDailyReports() {
    this.functionsService
      .callGetOrganizationFonasaDailyReports({
        organizationId: this.activeOrganization,
        uid: this.uid,
        impersonateToken: this.token,
      })
      .subscribe((data) => {
        this.loading = false;
        this.dailyreports = data
          .sort(
            (a: IFonasaReportItem, b: IFonasaReportItem) =>
              b.reportDate._seconds - a.reportDate._seconds
          )
          .map((report: IFonasaReportItem) => {
            const dateToShow = DateTime.fromSeconds(report.reportDate._seconds)
              .setZone('America/Santiago')
              .toFormat('dd-MM-yyyy');

            return {
              reportDate: dateToShow,
              downloadedAt: report.downloadedAt,
              numberOfCases: report.numberOfCases,
              newCasesReportPath: report.newCasesReportPath,
              id: report.id,
            };
          });
      });
  }

  downloadFonasaReport(item: IFonasaReportItem) {
    this.analyticsService.logEvent(
      this.analyticsService.events.download_fonasa_changes,
      {
        type: 'fonasa-changes',
        ...item,
      }
    );
    console.log(item);
    this.functionsService
      .callGetReportFileByPath({
        docId: item.id,
        file: 'urgency-law-cases',
        uid: this.uid,
        organizationId: this.activeOrganization,
        impersonateToken: this.token,
      })
      .subscribe((url) => {
        window.location.assign(url);
      });
  }
}

import { DateTime } from 'luxon';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { map } from 'rxjs';
import { IOrganization } from 'src/app/models/database.model';

interface userNameIndex {
  [key: string]: string;
}

interface ReportTimeStamp {
  _seconds: number;
  _nanoseconds: number;
}
export interface IReportItem {
  id: string;
  reportDate: ReportTimeStamp;
  downloadedAt: string[];
  quantity: string;
  reportPath: string;
  summaryPath: string;
  bondsExtractedDataCsvPath: string;
  dateCount?: number;
  fonasaExcelPath?: string;
  denialsExcelPath?: string;
  denialsNumberOfClaims?: number;
  fonasaNumberOfCases?: number;
}

export interface IUserOrganizations {
  uid: string;
  name: string;
  organizations: string[];
}
@Component({
  selector: 'app-bonos',
  templateUrl: './bonos.component.html',
  styleUrls: ['./bonos.component.scss'],
})
export class BonosComponent implements OnInit {
  dailyreports: IReportItem[] = [];
  uid = '';
  organizationId = '';
  token = '';
  isAdmin = false;
  userIndex: userNameIndex = {};
  showColumnDetailedInformation = false;
  currentOrganization = {} as IOrganization;
  activeOrganization = '';
  loading = false;
  constructor(
    private databaseService: DatabaseService,
    public authService: AuthService,
    public datepipe: DatePipe,
    public userService: UserService,
    private analyticsService: AnalyticsService,
    private functionsService: FunctionsService
  ) {
    this.userService.uid.subscribe((uid) => (this.uid = uid));
    this.userService.activeOrganization.subscribe((organization) => {
      this.activeOrganization = organization;
      this.setOrganizationsOfLoggedUserAndGetBonosDailyReports();
    });
    this.userService.token.subscribe((token) => (this.token = token));
    this.userService.isAdmin.subscribe((isAdmin) => (this.isAdmin = isAdmin));
  }

  ngOnInit(): void {
    this.setOrganizationsOfLoggedUserAndGetBonosDailyReports();
  }

  setOrganizationsOfLoggedUserAndGetBonosDailyReports() {
    this.loading = true;
    this.getUserInformationForReports().subscribe((user) => {
      this.getBonosDailyReports();
      this.databaseService
        .getOrganizationDocument(this.activeOrganization)
        .subscribe((organization) => {
          this.currentOrganization = organization;
          if (organization.id) {
            this.databaseService.getOrganizationSettingsDocument(organization.id).subscribe((organizationSettings) => {
              this.showColumnDetailedInformation =
                organizationSettings.products.extractBondsData.showModule;
            });
          }
        });
    });
  }

  getUserInformationForReports() {
    return this.databaseService.getUserDocument(this.uid).pipe(
      map((user): IUserOrganizations => {
        return {
          name: user.email,
          uid: user.id || '',
          organizations: user.organizations,
        };
      })
    );
  }

  getBonosDailyReports() {
    this.functionsService
      .callGetOrganizationBondsDailyReports({
        organizationId: this.activeOrganization,
        uid: this.uid,
        impersonateToken: this.token,
      })
      .subscribe((data) => {
        this.loading = false;
        let currentDate: string;
        let currentNumber = 0;
        this.dailyreports = data
          .sort(
            (a: IReportItem, b: IReportItem) =>
              b.reportDate._seconds - a.reportDate._seconds
          )
          .toReversed()
          .map((report: IReportItem) => {
            const dateToShow = DateTime.fromSeconds(report.reportDate._seconds)
              .setZone('America/Santiago')
              .toFormat('dd-MM-yyyy');
            if (currentDate !== dateToShow) {
              currentNumber = 1;
              currentDate = dateToShow;
            } else {
              currentNumber++;
            }
            currentDate = dateToShow;

            return {
              id: report.id,
              reportDate: dateToShow,
              downloadedAt: report.downloadedAt,
              quantity: report.quantity,
              reportPath: report.reportPath,
              summaryPath: report.summaryPath,
              bondsExtractedDataCsvPath: report.bondsExtractedDataCsvPath,
              dateCount: currentNumber,
            };
          })
          .toReversed();
      });
  }

  downloadResumen(item: IReportItem) {
    this.analyticsService.logEvent(
      this.analyticsService.events.download_resumen_excel,
      {
        type: 'resumen-excel',
        ...item,
      }
    );
    this.functionsService
      .callGetReportFileByPath({
        docId: item.id,
        file: 'summary',
        uid: this.uid,
        organizationId: this.activeOrganization,
        impersonateToken: this.token,
      })
      .subscribe((url) => {
        window.location.assign(url);
      });
  }

  downloadZip(item: IReportItem) {
    this.analyticsService.logEvent(this.analyticsService.events.download_zip, {
      type: 'zip',
      ...item,
    });
    if (!this.isAdmin) {
      this.functionsService
        .callUpdateDailyReportsDownloaded(
          this.uid,
          this.activeOrganization,
          item.id
        )
        .subscribe();
      item.downloadedAt.push(DateTime.now().toISO() as string);
    }
    this.functionsService
      .callGetReportFileByPath({
        docId: item.id,
        file: 'zip',
        uid: this.uid,
        organizationId: this.activeOrganization,
        impersonateToken: this.token,
      })
      .subscribe((url) => {
        window.location.assign(url);
      });
  }

  downloadBondsDataExtracted(item: IReportItem) {
    this.analyticsService.logEvent(
      this.analyticsService.events.download_bonds_data_excel,
      {
        type: 'bonds-data',
        ...item,
      }
    );
    this.functionsService
      .callGetReportFileByPath({
        docId: item.id,
        file: 'bondData',
        uid: this.uid,
        organizationId: this.activeOrganization,
        impersonateToken: this.token,
      })
      .subscribe((url) => {
        window.location.assign(url);
      });
  }
}

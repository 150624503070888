import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Chart, ChartConfiguration, registerables } from 'chart.js';
import { FunctionsService } from 'src/app/services/functions.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { formatNumberToMillionsClp } from 'src/app/components/constants';
import { DatabaseService } from 'src/app/services/database.service';

type IFonasaDashboardData = {
  urgencyLawCases: {
    dataSets: {
      general: {
        name: string;
        dataX: string[];
        dataY: number[];
        dataY2: number[];
      };
    };
  };
  secondProviderCases: {
    dataSets: {
      general: {
        name: string;
        dataX: string[];
        dataY: number[];
        dataY2: number[];
      };
    };
  };
  grdCases: {
    dataSets: {
      general: {
        name: string;
        dataX: string[];
        dataY: number[];
        dataY2: number[];
      };
    };
  };
};

@Component({
  selector: 'app-fonasa-dashboard',
  templateUrl: './fonasa-dashboard.component.html',
  styleUrls: ['./fonasa-dashboard.component.scss'],
})
export class FonasaDashboardComponent {
  showFonasaModule = true;
  activeOrganization = '';
  uid = '';
  loading = false;
  loadingDownload = false;
  token = '';
  showUrgencyLaw = false;
  showSecondProvider = false;
  showGrd = false;
  constructor(
    private userService: UserService,
    private functionService: FunctionsService,
    private analyticsService: AnalyticsService,
    private databaseService: DatabaseService
  ) {
    this.userService.uid.subscribe((uid) => (this.uid = uid));
    this.userService.token.subscribe((token) => (this.token = token));
    this.userService.activeOrganization.subscribe((organization) => {
      this.activeOrganization = organization;
      this.databaseService.getOrganizationSettingsDocument(this.activeOrganization).subscribe((settings) => {
        this.showFonasaModule = settings.products.fonasa.showModule ?? false;
        this.showUrgencyLaw = settings.products.fonasa.enableUrgencyLaw ?? false;
        this.showSecondProvider = settings.products.fonasa.enableSecondProvider ?? false;
        this.showGrd = settings.products.fonasa.enableGrd ?? false;
      });
    });
  }

  ngOnInit(): void {
    this.showFonasaDashboardChart();
  }

  downloadFonasaDatabase() {
    this.analyticsService.logEvent(
      this.analyticsService.events.download_fonasa_database,
      {
        type: 'fonasa-database',
      }
    );
    this.loadingDownload = true;
    if (!this.uid) throw new Error('No uid');
    this.functionService
      .callFonasaDownloadDatabase(this.uid, this.activeOrganization, this.token)
      .subscribe((url: string) => {
        this.loadingDownload = false;
        window.open(url);
      });
  }

  showFonasaDashboardChart() {
    this.loading = true;
    if (!this.uid) throw new Error('No uid');
    Chart.defaults.color = 'white';
    this.functionService
      .callFonasaDashboardData(this.uid, this.activeOrganization, this.token)
      .subscribe((fonasaDashboardData: IFonasaDashboardData) => {
        this.loading = false;
        if (this.showUrgencyLaw) {
          this.showUrgencyLawDasboardChart(fonasaDashboardData);
        }
        if (this.showSecondProvider) {
          this.showSecondProviderDasboardChart(fonasaDashboardData);
        }
        if (this.showGrd) {
          this.showGrdDasboardChart(fonasaDashboardData);
        }
      });
  }

  showUrgencyLawDasboardChart(fonasaDashboardData: IFonasaDashboardData) {
    const fonasaUrgencyLawDashboardChart = Chart.getChart(
      'fonasaUrgencyLawDashboardChart'
    );
    Chart.register(...registerables);
    const chart = new Chart('fonasaUrgencyLawDashboardChart', {
      type: 'bar',
      data: {
        labels: fonasaDashboardData.urgencyLawCases.dataSets.general.dataX,
        datasets: [
          {
            label: 'Cantidad de casos',
            data: fonasaDashboardData.urgencyLawCases.dataSets.general.dataY2,
            borderWidth: 2,
            borderRadius: 1,
            yAxisID: 'y1',
            datalabels: {
              align: 'start',
              anchor: 'end',
            },
          },
          {
            label: 'Monto total ($CLP millones)',
            data: fonasaDashboardData.urgencyLawCases.dataSets.general.dataY,
            borderWidth: 2,
            type: 'line',
            yAxisID: 'y2',
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: 'rgba(236,73,104,255)',
            },
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                const yAxisID = context.dataset.yAxisID;
                if (yAxisID === 'y2')
                  return (
                    label + ': ' + formatNumberToMillionsClp(context.parsed.y, 1)
                  );
                return label + ': ' + context.parsed.y;
              },
            },
          },
        },
        indexAxis: 'x',
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y1: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              color: 'rgba(236,73,104,255)',
              callback: function (value, _, __) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  showSecondProviderDasboardChart(fonasaDashboardData: IFonasaDashboardData) {
    const fonasaSecondProviderDashboardChart = Chart.getChart(
      'fonasaSecondProviderDashboardChart'
    );
    Chart.register(...registerables);
    const chart = new Chart('fonasaSecondProviderDashboardChart', {
      type: 'bar',
      data: {
        labels: fonasaDashboardData.secondProviderCases.dataSets.general.dataX,
        datasets: [
          {
            label: 'Cantidad de casos',
            data: fonasaDashboardData.secondProviderCases.dataSets.general
              .dataY2,
            borderWidth: 2,
            borderRadius: 1,
            yAxisID: 'y1',
            datalabels: {
              align: 'start',
              anchor: 'end',
            },
          },
          {
            label: 'Monto total ($CLP millones)',
            data: fonasaDashboardData.secondProviderCases.dataSets.general
              .dataY,
            borderWidth: 2,
            type: 'line',
            yAxisID: 'y2',
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: 'rgba(236,73,104,255)',
            },
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                const yAxisID = context.dataset.yAxisID;
                if (yAxisID === 'y2')
                  return (
                    label + ': ' + formatNumberToMillionsClp(context.parsed.y, 1)
                  );
                return label + ': ' + context.parsed.y;
              },
            },
          },
        },
        indexAxis: 'x',
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y1: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              color: 'rgba(236,73,104,255)',
              callback: function (value, _, __) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  showGrdDasboardChart(fonasaDashboardData: IFonasaDashboardData) {
    Chart.getChart('grdDashboardChart');
    Chart.register(...registerables);
    new Chart('grdDashboardChart', {
      type: 'bar',
      data: {
        labels: fonasaDashboardData.grdCases.dataSets.general.dataX,
        datasets: [
          {
            label: 'Cantidad de casos',
            data: fonasaDashboardData.grdCases.dataSets.general.dataY2,
            borderWidth: 2,
            borderRadius: 1,
            yAxisID: 'y1',
            datalabels: {
              align: 'start',
              anchor: 'end',
            },
          },
          {
            label: 'Monto total ($CLP millones)',
            data: fonasaDashboardData.grdCases.dataSets.general.dataY,
            borderWidth: 2,
            type: 'line',
            yAxisID: 'y2',
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: 'rgba(236,73,104,255)',
            },
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                const yAxisID = context.dataset.yAxisID;
                if (yAxisID === 'y2')
                  return (
                    label + ': ' + formatNumberToMillionsClp(context.parsed.y, 1)
                  );
                return label + ': ' + context.parsed.y;
              },
            },
          },
        },
        indexAxis: 'x',
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y1: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              color: 'rgba(236,73,104,255)',
              callback: function (value, _, __) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }
}

<div class="container-fluid mt--6">
  <div class="row">
    <div class="col-xl-6">
      <div class="card bg-charts" style="position: relative;">
        <div style="position: absolute; top: 50%; right: 45%;" *ngIf="loading">
          <img src="../../../assets/ball-triangle.svg" />
        </div>
        <div class="card-header bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-light text-uppercase ls-1 mb-1">Volumen</h6>
              <h3 class="h3 text-white mb-0">Número de cuentas diarias (#)</h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart">
            <canvas id="bonosPerDay" class="chart-canvas"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card bg-charts" style="position: relative;">
        <div style="position: absolute; top: 50%; right: 45%;" *ngIf="loading">
          <img src="../../../assets/ball-triangle.svg" />
        </div>
        <div class="card-header bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-light text-uppercase ls-1 mb-1">Montos</h6>
              <h3 class="h3 text-white mb-0 nav-link-text">
                Montos diarios ($CLP millones)
              </h3>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart">
            <canvas
              id="comparacionBonificadoPayors"
              class="chart-canvas"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6 pt-5">
  <div class="row">
    <div class="col-xl-6">
      <div class="card bg-charts" style="position: relative;">
        <div style="position: absolute; top: 50%; right: 45%;" *ngIf="loading">
          <img src="../../../assets/ball-triangle.svg" />
        </div>
        <div class="card-header bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-light text-uppercase ls-1 mb-1">Montos</h6>
              <h3 class="h3 text-white mb-0 nav-link-text">
                Número de cuentas semanales (#) y ticket promedio de bonos ($CLP millones)
              </h3>
              <div class="h7 text-light text-uppercase ls-1 mb-1"></div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart">
            <canvas id="bondsWeeklyTendency" class="chart-canvas"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card bg-charts" style="position: relative;">
        <div style="position: absolute; top: 50%; right: 45%;" *ngIf="loading">
          <img src="../../../assets/ball-triangle.svg" />
        </div>
        <div class="card-header bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-light text-uppercase ls-1 mb-1">Montos</h6>
              <h3 class="h3 text-white mb-0 nav-link-text">
                Monto bonificado ($CLP millones) y bonificación promedio (%)
              </h3>
              <div class="h7 text-light text-uppercase ls-1 mb-1"></div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart">
            <canvas
              id="ReimbursementAmountAndPercentageByWeek"
              class="chart-canvas"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6 pt-5">
  <div class="row">
    <div class="col-xl-6">
      <div class="card bg-charts" style="position: relative;">
        <div style="position: absolute; top: 50%; right: 45%;" *ngIf="loading">
          <img src="../../../assets/ball-triangle.svg" />
        </div>
        <div class="card-header bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-light text-uppercase ls-1 mb-1">Volumen</h6>
              <h3 class="h3 text-white mb-0 nav-link-text">
                Número de cuentas por Isapre (#)
              </h3>
              <div class="h7 text-light text-uppercase ls-1 mb-1">
                últimos 7 días
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart">
            <canvas id="bondCountPerPayorPerDate" class="chart-canvas"></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card bg-charts" style="position: relative;">
        <div style="position: absolute; top: 50%; right: 45%;" *ngIf="loading">
          <img src="../../../assets/ball-triangle.svg" />
        </div>
        <div class="card-header bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-light text-uppercase ls-1 mb-1">Montos</h6>
              <h3 class="h3 text-white mb-0 nav-link-text">
                Monto bonificado por Isapre ($CLP millones)
              </h3>
              <div class="h7 text-light text-uppercase ls-1 mb-1">
                últimos 7 días
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart">
            <canvas id="payorReimbursementInTime" class="chart-canvas"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mt--6 pt-5">
  <div class="row">
    <div class="col-xl-6">
      <div class="card bg-charts" style="position: relative;">
        <div style="position: absolute; top: 50%; right: 45%;" *ngIf="loading">
          <img src="../../../assets/ball-triangle.svg" />
        </div>
        <div class="card-header bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-light text-uppercase ls-1 mb-1">Estadísticas</h6>
              <h3 class="h3 text-white mb-0 nav-link-text">
                Representación por Isapre en número de cuentas (%)
              </h3>
              <div class="h7 text-light text-uppercase ls-1 mb-1">
                últimos 7 días
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart">
            <canvas
              id="comparacionBonificadoIsapre"
              class="chart-canvas"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-6">
      <div class="card bg-charts" style="position: relative;">
        <div style="position: absolute; top: 50%; right: 45%;" *ngIf="loading">
          <img src="../../../assets/ball-triangle.svg" />
        </div>
        <div class="card-header bg-transparent">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="text-light text-uppercase ls-1 mb-1">Estadísticas</h6>
              <h3 class="h3 text-white mb-0 nav-link-text">
                Bonificación por Isapre (%)
              </h3>
              <div class="h7 text-light text-uppercase ls-1 mb-1">
                últimos 7 días
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="chart">
            <canvas
              id="porcentajeBonificadoPayors"
              class="chart-canvas"
            ></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

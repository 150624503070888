import { Component, OnDestroy, OnInit } from '@angular/core';
import { IExecutionLog, IMockConfigs } from 'src/app/models/database.model';
import { AuthService } from 'src/app/services/auth.service';
import { DatabaseService } from 'src/app/services/database.service';
import { FunctionsService } from 'src/app/services/functions.service';
import { DatePipe } from '@angular/common';
import { UserService } from 'src/app/services/user.service';
import { first } from 'rxjs/internal/operators/first';
import { Subscription } from 'rxjs';

interface ILogDetails {
  id: string;
  title: string;
  logs: IExecutionLogContainer;
  date: string;
}

interface IExecutionLogContainer {
  [key: string]: {
    logs: IExecutionLog[];
    errors: boolean;
    completed: boolean;
    subtitle: string;
    institutionName: string;
    subtitleSuccess: string;
  };
}
interface orgNameIndex {
  [key: string]: string;
}

export interface ExtractBondDataInput {
  bondIds: string[];
}
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  getAllOrganizationsSubscriber: Subscription;
  executionLogs: IExecutionLog[] = [];
  executeScrapperButtonDisabled = false;
  admin = false;
  executionStartDate = '';
  executionEndDate = '';
  executionSaveDate = '';
  executionOrgName = '';
  executionOrganization = '';
  executionPayor = '';
  textToEncrypt = '';
  bondIdToExtractData = '';
  bondExtractedData = '';
  checkMode = 'daily';
  allOrganizations = false;
  allPayors = false;
  encryptedTextResult = '';
  userList: { name: string; uid: string; organizations: string[] }[] = [];
  organizationList: { name: string; id: string }[] = [];
  mockConfigs: IMockConfigs[] = [];
  logDetails: ILogDetails[] = [];
  executionIdList: string[] = [];
  payorList: string[] = [
    'banmedica',
    'masvida',
    'vida-tres',
    'consalud',
    'consalud-v2',
    'colmena',
    'colmena-imed',
    'cruz-blanca',
    'fonasa',
  ];
  orgIndex: orgNameIndex = {};
  filteredUserOptions: { name: string; uid: string }[] = [];
  checkModeArray = new Map<string, string>([
    ['daily', 'Daily Check'],
    ['metadata', 'IDs Check'],
    ['metadata-cost', 'IDs and Cost Check'],
    ['policer', 'Policer Check'],
  ]);
  logQuantity = 50;
  scraperTarget = 'bond-download';
  scraperTargetArray = new Map<string, string>([
    ['bond-download', 'Bond Download'],
    ['claims', 'Claims'],
  ]);

  constructor(
    private databaseService: DatabaseService,
    public authService: AuthService,
    private functionService: FunctionsService,
    public userService: UserService
  ) {
    this.userService.isAdmin.subscribe((isAdmin) => (this.admin = isAdmin));
    this.getAllOrganizationsSubscriber = this.databaseService
      .getAllOrganizations()
      .subscribe((organizations) => {
        this.organizationList = organizations.map((organization) => {
          this.orgIndex[organization.id || 'noId'] = organization.name;
          return {
            name: organization.name,
            id: organization.id || '',
          };
        });
      });
  }
  ngOnDestroy(): void {
    this.getAllOrganizationsSubscriber.unsubscribe();
  }

  filterOrganizations() {
    return this.organizationList.filter((item: any) => {
      return item.name
        .toLowerCase()
        .startsWith(this.executionOrgName.toLowerCase());
    });
  }

  findOrganization(userName: string) {
    const similars = this.filterOrganizations();
    if (similars.length === 1 && similars[0].name === userName) {
      this.executionOrganization = similars[0].id;
    } else {
      this.executionOrgName = '';
    }
  }

  selectOrganization(selectedOrganization: { name: string; id: string }) {
    this.executionOrgName = selectedOrganization.name;
    this.executionOrganization = selectedOrganization.id;
    (<HTMLInputElement>document.getElementById('example-org-input')).value =
      selectedOrganization.name;
  }

  filterPayors() {
    return this.payorList.filter((item: any) => {
      return item.toLowerCase().startsWith(this.executionPayor.toLowerCase());
    });
  }

  selectCheckMode(mode: string) {
    this.checkMode = mode;
  }

  selectTargetMode(mode: string) {
    this.scraperTarget = mode;
  }

  selectPayor(selectedPayor: string) {
    this.executionPayor = selectedPayor;
  }

  ngOnInit(): void {
    this.getScrapperExecutionLogs();
    this.getAdminData();
    this.getMockConfigs();
  }

  getAdminData() {
    this.databaseService.getAllUsers().subscribe((users) => {
      this.userList = users.map((user) => {
        return {
          name: user.email,
          uid: user.id || '',
          organizations: user.organizations,
        };
      });
    });
  }

  getOrganizationOfLoggedUser(uid: string) {
    const allUsers = this.userList;
    const user = allUsers.find((user) => user.uid === uid);
    return user?.organizations[0];
  }

  getScrapperExecutionLogs() {
    if (this.logQuantity > 5000) this.logQuantity = 5000;
    this.databaseService
      .getExecutionLogs(this.logQuantity)
      .pipe(first())
      .subscribe((data) => {
        const nonSystemLogs = data.filter((log) => log.owner != 'System');
        this.executionLogs = nonSystemLogs;
        this.groupScraperLogs(nonSystemLogs);
      });
  }

  groupScraperLogs(logs: IExecutionLog[]) {
    this.logDetails = [];
    const groups: Record<string, IExecutionLog[]> = {};
    const groupsByOwner: Record<string, IExecutionLogContainer> = {};
    logs.forEach((log) => {
      if (groups[log.executionId] == undefined) {
        groups[log.executionId] = [];
        groupsByOwner[log.executionId] = {};
      }
      if (groupsByOwner[log.executionId][log.owner] === undefined)
        groupsByOwner[log.executionId][log.owner] = {
          logs: [],
          completed: false,
          errors: false,
          subtitle: '',
          subtitleSuccess: '',
          institutionName: this.orgIndex[log.owner] || log.owner,
        };
      groupsByOwner[log.executionId][log.owner].logs.push(log);
      groups[log.executionId].push(log);
    });

    Object.keys(groups).forEach((id) => {
      const type =
        groups[id][0].type == 'reportGeneration' ? 'Report' : 'Scraper';
      Object.keys(groupsByOwner[id]).forEach((owner) => {
        const hasError = groupsByOwner[id][owner].logs
          .map((executionLog) => executionLog.status)
          .find((status) => status.toLocaleLowerCase().includes('error'));
        groupsByOwner[id][owner].errors = Boolean(hasError);
        const payors = groupsByOwner[id][owner].logs
          .map((executionLog) => executionLog.type)
          .filter((types) => types.includes('scrape'))
          .map((type) => type.replace(/scrape/g, ''));
        const finishedPayors = groupsByOwner[id][owner].logs
          .filter(
            (executionLog) =>
              executionLog.status.toLocaleLowerCase() == 'finished'
          )
          .map((executionLog) => executionLog.type)
          .filter((types) => types.includes('scrape'))
          .map((type) => type.replace(/scrape/g, ''));
        groupsByOwner[id][owner].completed = Boolean(
          new Set(payors).size === new Set(finishedPayors).size
        );
        groupsByOwner[id][owner].subtitle =
          type == 'Report'
            ? 'Report generated'
            : `Included Payors: ${[...new Set(payors)].join(', ')}`;
        groupsByOwner[id][owner].subtitleSuccess =
          type == 'Report' || payors.length == 0
            ? ''
            : `Successful Payors: ${[...new Set(finishedPayors)].join(', ')}`;
      });
      const date = groups[id][0].date;

      this.logDetails.push({
        id,
        title: `${type} Execution `,
        logs: groupsByOwner[id],
        date,
      });
    });
  }

  async openImage(image: string) {
    window.open(
      await this.functionService
        .callGetFileByPath({ filePath: image })
        .toPromise()
    );
  }

  impersonateUserWithUid(uid: string) {
    this.functionService
      .callGenerateImpersonateToken(uid)
      .subscribe((token) => {
        this.userService.token.next(token);
        this.userService.uid.next(uid);
      });
  }

  sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  async executeEncryptText() {
    if (!this.textToEncrypt) {
      console.log('Invalid text');
      return;
    }

    this.functionService
      .callEcryptText({ text: this.textToEncrypt })
      .subscribe((encryptedText) => {
        console.log(encryptedText);
        this.encryptedTextResult = encryptedText;
      });
  }

  async extractInfoFromBond() {
    if (!this.bondIdToExtractData) {
      console.log('Bond id is required');
      return;
    }
    const bondsIds: ExtractBondDataInput = { bondIds: [] };
    const bondIdsInput = this.bondIdToExtractData.split(',');
    for (const bondIdInput of bondIdsInput) {
      bondsIds.bondIds.push(bondIdInput.trim());
    }
    this.functionService
      .callBondDataExtraction(bondsIds)
      .subscribe((bondExtractedData) => {
        console.log(bondExtractedData);
        this.bondExtractedData = JSON.stringify(bondExtractedData, null, 2);
      });
  }

  async executeScrapper() {
    console.log('Executing Scrapper');
    const start = new DatePipe('en-US').transform(
      this.executionStartDate,
      'MM/dd/yyyy'
    );
    const end = new DatePipe('en-US').transform(
      this.executionEndDate,
      'MM/dd/yyyy'
    );

    const save = new DatePipe('en-US').transform(
      this.executionSaveDate,
      'MM/dd/yyyy'
    );

    if (!start || !end || !save) {
      console.log('Invalid dates', start, end);
      return;
    }
    console.log({ start, end });
    this.functionService
      .callStartScraperProcess({
        start,
        end,
        save,
        orgId: this.executionOrganization,
        payorId: this.executionPayor,
        checkMode: this.checkMode,
        scraperTarget: this.scraperTarget,
      })
      .subscribe((result) => console.log({ result }));
  }

  async executeReport() {
    console.log('Executing Report');
    const date = new DatePipe('en-US').transform(
      this.executionSaveDate,
      'MM/dd/yyyy'
    );

    if (!date) {
      console.log('Invalid date'), date;
      return;
    }
    console.log({ date });

    this.functionService
      .callStartReportProcess({
        date,
        orgId: this.executionOrganization,
      })
      .subscribe((result) => {
        console.log({ result });
      });
  }

  async executeDailyAlert() {
    console.log('Sending Daily Alert');
    const date = new DatePipe('en-US').transform(
      this.executionSaveDate,
      'MM/dd/yyyy'
    );

    if (!date) {
      console.log('Invalid date'), date;
      return;
    }
    console.log({ date });

    this.functionService
      .callSendDailyAlert({
        date,
      })
      .subscribe((result) => {
        console.log({ result });
      });
  }

  async getMockConfigs() {
    this.databaseService.getAllMockConfigs().subscribe((configs) => {
      this.mockConfigs = configs;
    });
  }

  async updateMockConfig(
    mock: IMockConfigs,
    throwError500: boolean,
    timeOutError: boolean,
    bondsNotFound: boolean,
    waitingForDownload: boolean
  ) {
    this.databaseService.updateMockConfigs(
      mock.id as string,
      throwError500,
      timeOutError,
      bondsNotFound,
      waitingForDownload
    );
  }
}

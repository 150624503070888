<div>
  <div class="pb-8 header bg-blue-light-finapsys">
    <div class="container-fluid">
      <div class="header-body">
        <div class="py-4 row align-items-center">
          <div class="col-lg-6 col-7">
            <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-0">
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a class="h2 nav-link-text">Admin</a>
                </li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt--8">
  <div class="main-content" id="panel">
    <!-- Topnav -->

    <!-- Page content -->
    <div class="container-fluid mt--6">
      <div class="row">
        <!-- Light table -->
        <div class="col">

          <div class="card col-xl-12">
            <!-- Card header -->
            <div class="card card-frame">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-xl-2">
                    <label for="example-date-input" class="form-control-label">Start Date</label>
                    <input class="form-control" type="date" [(ngModel)]="executionStartDate" id="example-date-input" />
                  </div>

                  <div class="form-group col-xl-2">
                    <label for="example-date-input" class="form-control-label">End Date</label>
                    <input class="form-control" type="date" [(ngModel)]="executionEndDate" id="example-date-input" />
                  </div>

                  <div class="form-group col-xl-2">
                    <label for="example-date-input" class="form-control-label">Save Date</label>
                    <input class="form-control" type="date" [(ngModel)]="executionSaveDate" id="example-date-input" />
                  </div>
                  <div class="form-group col-xl-2">
                    <label for="example-date-input" class="form-control-label">Organization</label>
                    <input class="form-control" type="text" data-toggle="dropdown" [(ngModel)]="executionOrgName"
                      (change)="findOrganization(executionOrgName)" id="example-org-input"
                      [disabled]="allOrganizations" />
                    <div class="dropdown-menu">
                      <a *ngFor="let organization of filterOrganizations()" class="dropdown-item text-blue-finapsys"
                        (click)="selectOrganization(organization)">{{
                        organization.name }}</a>
                      <a *ngIf="filterOrganizations().length === 0" class="dropdown-item text-blue-finapsys">No
                        organization matches input</a>
                    </div>
                  </div>
                  <div class="form-group col-xl-2">
                    <label for="example-date-input" class="form-control-label">Payor</label>
                    <input class="form-control" type="text" data-toggle="dropdown" [(ngModel)]="executionPayor"
                      id="example-payor-input" [disabled]="allPayors" />
                    <div class="dropdown-menu">
                      <a *ngFor="let payor of filterPayors()" class="dropdown-item text-blue-finapsys"
                        (click)="selectPayor(payor)">{{
                        payor }}</a>
                      <a *ngIf="filterPayors().length === 0" class="dropdown-item text-blue-finapsys">No payor matches
                        input</a>
                    </div>
                  </div>
                  <div class="mt-4 form-group col-xl-2">
                    <div class="pl-5 row">
                      <input type="checkbox" value="allOrganizations" class="form-check-input"
                        (change)="allOrganizations = allOrganizations ? false : true; executionOrganization = ''; executionOrgName= ''"
                        id="organizationCheck">
                      <label class="form-check-label form-check-sign-white text-blue-finapsys"
                        for="organizationCheck">All organizations</label>
                    </div>
                    <div class="pl-5 row">
                      <input type="checkbox" value="allPayors" class="form-check-input"
                        (change)="allPayors = allPayors ? false : true; executionPayor = ''" id="payorCheck">
                      <label class="form-check-label form-check-sign-white text-blue-finapsys" for="payorCheck">All
                        payors</label>
                    </div>
                    <div class="pl-4 row">
                      <div class="dropdown">
                        <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          {{checkModeArray.get(checkMode)}}
                        </button>
                        <div class="dropdown-menu text-blue-finapsys" aria-labelledby="dropdownMenuButton">
                          <a *ngFor="let pair of checkModeArray | keyvalue" class="dropdown-item"
                            (click)="selectCheckMode(pair.key)">{{
                            pair.value }}</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <button (click)="executeScrapper()"
                    [disabled]="!executionStartDate || !executionEndDate || !executionSaveDate || (!allPayors && !executionPayor) || (!allOrganizations && !executionOrganization)"
                    class="btn btn-icon btn-3 bg-blue-light-finapsys" type="button">
                    <span class="btn-inner--icon text-blue-finapsys"><i class="ni ni-bag-17"></i></span>
                    <span class="btn-inner--text text-blue-finapsys">Run {{scraperTargetArray.get(scraperTarget)}} for
                      {{ executionStartDate }} -
                      {{ executionEndDate }} (Save date: {{executionSaveDate}})</span>
                  </button>
                  <button class="btn btn-secondary dropdown-toggle bg-blue-finapsys text-white-finapsys" type="button"
                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    [disabled]="!executionStartDate || !executionEndDate || !executionSaveDate || (!allPayors && !executionPayor) || (!allOrganizations && !executionOrganization)">
                    {{scraperTargetArray.get(scraperTarget)}}
                  </button>
                  <div class="dropdown-menu text-blue-finapsys" aria-labelledby="dropdownMenuButton">
                    <a *ngFor="let pair of scraperTargetArray | keyvalue" class="dropdown-item"
                      (click)="selectTargetMode(pair.key)">{{
                      pair.value }}</a>
                  </div>
                  <button (click)="executeReport()" [disabled]="!executionSaveDate || (!allOrganizations && !executionOrganization)"
                    class="btn btn-icon btn-3 bg-blue-light-finapsys" type="button">
                    <span class="btn-inner--icon text-blue-finapsys"><i class="ni ni-bag-17"></i></span>
                    <span class="btn-inner--text text-blue-finapsys">Run Reports for ({{ executionSaveDate }})</span>
                  </button>
                  <button (click)="executeDailyAlert()" [disabled]="!executionSaveDate"
                    class="btn btn-icon btn-3 bg-blue-light-finapsys" type="button">
                    <span class="btn-inner--icon text-blue-finapsys"><i class="ni ni-bag-17"></i></span>
                    <span class="btn-inner--text text-blue-finapsys">Send Daily Slack alert ({{ executionSaveDate
                      }})</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="btn-group">
            <button type="button" class="btn btn-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false" [disabled]="!userList">
              Impersonate user - BE CAREFUL!!
            </button>
            <div class="dropdown-menu">
              <a *ngFor="let user of userList" class="dropdown-item text-blue-finapsys"
                (click)="impersonateUserWithUid(user.uid)">{{
                user.name }}</a>
            </div>
          </div>

          <!-- /btn-group -->
          <div class="card col-xl-8">
            <!-- Card header -->
            <div class="border-0 card-header">
              <h3 class="mb-0">Encrypt text</h3>
            </div>
            <div class="card card-frame">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-xl-12">
                    <label for="example-date-input" class="form-control-label">Text to encrypt. Userful for new payor
                      passwords</label>
                    <input class="form-control" type="text" [(ngModel)]="textToEncrypt" id="example-date-input" />
                  </div>
                </div>
                <span *ngIf="encryptedTextResult" class="text-blue-finapsys">
                  <p>Result: {{ encryptedTextResult }}</p>
                </span>
                <button (click)="executeEncryptText()" class="btn btn-icon btn-3 btn-primary" type="button">
                  <span class="btn-inner--icon"><i class="ni ni-bag-17"></i></span>
                  <span class="btn-inner--text">Encrypt!</span>
                </button>
              </div>
            </div>
          </div>

          <div class="card card-frame">
            <div class="border-0 card-header">
              <h3 class="mb-0">Extract bond data</h3>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="form-group col-xl-12">
                  <label for="example-date-input" class="form-control-label">Enter the id of the bond to be
                    parsed</label>
                  <input class="form-control" type="text" [(ngModel)]="bondIdToExtractData" id="example-date-input" />
                </div>
              </div>
              <span *ngIf="bondExtractedData">
                <pre>{{ bondExtractedData }}</pre>
              </span>
              <button (click)="extractInfoFromBond()" class="btn btn-icon btn-3 btn-primary" type="button">
                <span class="btn-inner--icon"><i class="ni ni-bag-17"></i></span>
                <span class="btn-inner--text">Parse!</span>
              </button>
            </div>
          </div>

          <div class="accordion" id="accordionExample">
            <div class="card col-xl-14">
              <div class="border-0 card-header">
                <div class="row">
                  <div class="form-group col-xl-7">
                    <button (click)="getScrapperExecutionLogs()" class="btn btn-icon btn-3 btn-primary" type="button">
                      <span class="btn-inner--icon"><i class="ni ni-cloud-download-95"></i></span>
                      <span class="btn-inner--text">Update Execution logs</span>
                    </button>
                  </div>
                  <div class="form-group col-xl-2">
                    <label>Log Quantity:</label>
                  </div>
                  <div class="form-group col-xl-2">
                    <input class="form-control" type="text" [(ngModel)]="logQuantity" placeholder="Log Quantity: 100" />
                  </div>
                </div>
                <div class="card card-frame" *ngFor="let details of logDetails">
                  <div class=" card card-frame" *ngFor="let owner of details.logs | keyvalue; index as i">
                    <div class="card-header" id="headingOne" data-toggle="collapse"
                      [attr.data-target]="'#collapse-' + details.id + '-' + i" aria-expanded="true"
                      aria-controls="collapseOne">
                      <span class="mr-4 badge badge-dot"><i
                          [ngClass]="owner.value.completed ? (owner.value.errors ? 'bg-warning' : 'bg-success' ) : 'bg-danger'"></i>
                        <span class="status text-blue-finapsys">{{owner.value.completed ? (owner.value.errors ?
                          'Completed with errors' :
                          'Completed'
                          )
                          : 'Error'}}</span>
                      </span>
                      <h3 id="profile-span-classbadge-badge-primary-ml-2prospan">
                        {{details.title}} - {{details.date | date}} - {{ owner.value.institutionName }}
                        <span class="ml-2 badge badge-primary">{{details.id}}</span>
                      </h3>
                    </div>
                    <div id="collapse-{{details.id}}-{{i}}" class="collapse hide" aria-labelledby="headingOne"
                      data-parent="#accordionExample">
                      <div class="card-body">
                        <p>{{owner.value.subtitle}}</p>
                        <p>{{owner.value.subtitleSuccess}}</p>
                        <div class="table-responsive">
                          <table class="table align-items-center table-flush">
                            <thead class="thead-light">
                              <tr>
                                <th scope="col" class="sort" data-sort="Date">Date</th>
                                <th scope="col" class="sort" data-sort="Type">Type</th>
                                <th scope="col" class="sort" data-sort="Status">Status</th>
                                <th scope="col" class="sort" data-sort="Message">
                                  Message
                                </th>
                              </tr>
                            </thead>
                            <tbody class="list">
                              <tr *ngFor="let log of owner.value.logs; index as i">
                                <td scope="row">
                                  {{ log.date | date : "dd/MM/yyyy hh:mm:ss" }}
                                </td>
                                <td>{{ log.type }}</td>
                                <td>
                                  {{ log.status }}
                                  <a *ngIf="log.image" target="_blank" (click)="openImage(log.image)"
                                    class="badge badge-pill badge-warning">Open
                                    Link</a>
                                </td>
                                <td>{{ log.message }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card col-xl-12">
              <div class="border-0 card-header">
                <h3 class="mb-0">Mocks Test Bed</h3>
              </div>
              <div class="col">
                <!-- Light table -->
                <div class="col">
                  <div class="card">
                    <!-- Card header -->
                    <!-- Light table -->
                    <div class="table-responsive" data-toggle="list" data-list-values='["Mocks", "Flags"]'>
                      <table class="table align-items-center table-flush">
                        <thead class="thead">
                          <tr>
                            <th scope="col" class="text-center sort">
                              <h4>Mocks</h4>
                            </th>
                            <th scope="col" class="text-center sort">
                              <h4>Flags</h4>
                            </th>
                          </tr>
                        </thead>
                        <ng-container *ngFor="let mock of mockConfigs">
                          <tbody class="list">
                            <tr>
                              <td class="text-center">
                                <h5>{{ mock.id }}</h5>
                              </td>
                              <td class="text-center Flags row">
                                <div style="margin: 5px">
                                  <button class="btn btn-danger btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      true,
                                      false,
                                      false,
                                      false
                                    )
                                  " *ngIf="!mock.throwError500">
                                    Throw Error 500
                                  </button>
                                  <button class="btn btn-success btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      true,
                                      false,
                                      false,
                                      false
                                    )
                                  " *ngIf="mock.throwError500">
                                    Throw Error 500
                                  </button>
                                </div>
                                <div style="margin: 5px">
                                  <button class="btn btn-danger btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      false,
                                      true,
                                      false,
                                      false
                                    )
                                  " *ngIf="!mock.timeOutError">
                                    Time Out
                                  </button>
                                  <button class="btn btn-success btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      false,
                                      true,
                                      false,
                                      false
                                    )
                                  " *ngIf="mock.timeOutError">
                                    Time Out
                                  </button>
                                </div>
                                <div style="margin: 5px">
                                  <button class="btn btn-danger btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      false,
                                      false,
                                      true,
                                      false
                                    )
                                  " *ngIf="!mock.bondsNotFound">
                                    Bonds Not Found
                                  </button>
                                  <button class="btn btn-success btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      false,
                                      false,
                                      true,
                                      false
                                    )
                                  " *ngIf="mock.bondsNotFound">
                                    Bonds Not Found
                                  </button>
                                </div>
                                <div style="margin: 5px">
                                  <button class="btn btn-danger btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      false,
                                      false,
                                      false,
                                      true
                                    )
                                  " *ngIf="!mock.waitingForDownload">
                                    Waiting For Download
                                  </button>
                                  <button class="btn btn-success btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      false,
                                      false,
                                      false,
                                      true
                                    )
                                  " *ngIf="mock.waitingForDownload">
                                    Waiting For Download
                                  </button>
                                </div>
                                <div style="margin: 5px">
                                  <button class="btn btn-secondary btn-lg" type="button" (click)="
                                    updateMockConfig(
                                      mock,
                                      false,
                                      false,
                                      false,
                                      false
                                    )
                                  ">
                                    Reset
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </ng-container>
                      </table>
                    </div>
                    <!-- Card footer -->
                  </div>
                </div>
              </div>
            </div>


            <div class="card">
              <!-- Card header -->
              <div class="border-0 card-header">
                <h3 class="mb-0">Execution Logs</h3>
              </div>
              <!-- Light table -->
              <div class="table-responsive">
                <table class="table align-items-center table-flush">
                  <thead class="thead-light">
                    <tr>
                      <th scope="col" class="sort" data-sort="Date">Date</th>
                      <th scope="col" class="sort" data-sort="Type">Type</th>
                      <th scope="col" class="sort" data-sort="Status">Status</th>
                      <th scope="col" class="sort" data-sort="Owner">Owner</th>
                      <th scope="col" class="sort" data-sort="Message">
                        Message
                      </th>
                      <th scope="col" class="sort" data-sort="ExecutionId">
                        ExecutionId
                      </th>
                    </tr>
                  </thead>
                  <tbody class="list">
                    <tr *ngFor="let log of executionLogs; index as i">
                      <th scope="row">
                        {{ log.date | date : "dd/MM/yyyy hh:mm:ss" }}
                      </th>
                      <td>{{ log.type }}</td>
                      <td>{{ log.status }}
                        <a *ngIf="log.image" target="_blank" (click)="openImage(log.image)"
                          class="badge badge-pill badge-warning">Image
                          Link</a>
                      </td>
                      <td>{{ log.owner }}</td>
                      <td>{{ log.message }}</td>
                      <td>{{ log.executionId }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Card footer -->
              <div class="py-4 card-footer">
                <nav aria-label="...">
                  <ul class="mb-0 pagination justify-content-end">
                    <li class="page-item disabled">
                      <a class="page-link" href="#" tabindex="-1">
                        <i class="fas fa-angle-left"></i>
                        <span class="sr-only">Previous</span>
                      </a>
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="#">1</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">2 <span class="sr-only">(current)</span></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">3</a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        <i class="fas fa-angle-right"></i>
                        <span class="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { IDataSet } from '../dashboard/dashboard.component';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Subject, takeUntil } from 'rxjs';
import { formatNumberToMillionsClp } from '../constants';

@Component({
  selector: 'app-bonos-chart',
  templateUrl: './bonos-chart.component.html',
  styleUrls: ['./bonos-chart.component.scss'],
})
export class BonosChartComponent implements OnInit, OnDestroy {
  loading = false;
  public dashBoardData$ = this.dashboardService.dashBoardData$;
  private destroy$ = new Subject<void>();

  constructor(public dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.bonosChart();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  bonosChart() {
    Chart.defaults.color = 'white';

    this.dashBoardData$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (this.dashboardService.isDashboardSet === false) this.loading = true;
      this.bondQuantityInTime(data.dataSets.dataSetbondQuantityInTime);
      this.bondPercentajePerPayor(data.dataSets.dataSetBondPercentajePerPayor);
      this.bondsAmountsInTime(data.dataSets.dataSetbondsAmountsInTime);
      this.payorBondQuantityDoughnutChart(data.dataSets.dataSetPayorsBondCount);
      this.bondsAmountInTimePayors(
        data.dataSets.dataSetPayorsReimbursementInTime
      );
      this.bondsQuantityAndAverageAmountByWeek(
        data.dataSets.dataSetBondsQuantityAndAverageAmountByWeek
      );
      this.reimbursementAmountAndPercentageByWeek(
        data.dataSets.dataSetReimbursementAmountAndPercentageByWeek
      );
      this.bondsCountInTimePayors(
        data.dataSets.dataSetGetBondCountPerPayorPerDate
      );
      if (this.dashboardService.isDashboardSet === true) this.loading = false;
    });
  }

  bondQuantityInTime = async (data: IDataSet) => {
    const chartExist = Chart.getChart('bonosPerDay');
    if (chartExist != undefined) chartExist.destroy();

    const maxValue = Math.max(Number(...data.dataY));

    Chart.register(...registerables);
    const chart = new Chart('bonosPerDay', {
      type: 'line',
      data: {
        labels: data.dataX,
        datasets: [
          {
            label: 'Bonos',
            data: data.dataY,
            tension: 0.5,
            backgroundColor: '#5e72e4',
            borderColor: '#5e72e4',
            borderWidth: 4,
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                return (
                  label + ': ' + formatNumberToMillionsClp(context.parsed.y, 0)
                );
              },
            },
          },
          legend: {
            display: false,
          },
          datalabels: {
            formatter: function (value) {
              return formatNumberToMillionsClp(value, 0);
            },
            align: 'end',
            anchor: 'start',
          },
        },
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            suggestedMax: maxValue + 15,
            ticks: {
              callback: function (value) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  };

  bondPercentajePerPayor = (data: IDataSet) => {
    const chartExist = Chart.getChart('porcentajeBonificadoPayors');
    if (chartExist != undefined) chartExist.destroy();
    Chart.register(...registerables);
    const chart = new Chart('porcentajeBonificadoPayors', {
      type: 'bar',
      data: {
        labels: data.dataX,
        datasets: [
          {
            label: '%',
            data: data.dataY,
            borderWidth: 2,
            backgroundColor: data.dataX.map((label) => this.getColor(label)),

            borderRadius: 1,
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            align: 'start',
            anchor: 'end',
            formatter: function (value) {
              return value + '%';
            },
          },
        },
        indexAxis: 'y',
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              callback: function (value, index, values) {
                return value + '%';
              },
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  };

  bondsAmountsInTime(data: IDataSet) {
    const chartExist = Chart.getChart('comparacionBonificadoPayors');
    if (chartExist != undefined) chartExist.destroy();

    Chart.register(...registerables);

    const chart = new Chart('comparacionBonificadoPayors', {
      type: 'line',
      data: {
        labels: data.dataX,
        datasets: [
          {
            label: 'Cobrado',
            data: data.dataY,
            borderWidth: 4,
            backgroundColor: 'rgba(236,73,104,255)',
            borderColor: 'rgba(236,73,104,255)',
            tension: 0.5,
          },
          {
            label: 'Bonificado',
            data: data.dataY2,
            borderWidth: 4,
            backgroundColor: '#5e72e4',
            borderColor: '#5e72e4',
            tension: 0.5,
          },
        ],
      },

      options: {
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y: {
            ticks: {
              callback: function (value, index) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                return (
                  label + ': ' + formatNumberToMillionsClp(context.parsed.y, 1)
                );
              },
            },
          },
        },
      },
    });
  }

  payorBondQuantityDoughnutChart = (data: IDataSet) => {
    const chartExist = Chart.getChart('comparacionBonificadoIsapre');
    if (chartExist != undefined) chartExist.destroy();
    Chart.register(...registerables);
    new Chart('comparacionBonificadoIsapre', {
      type: 'doughnut',
      data: {
        labels: data.dataX,
        datasets: [
          {
            label: '%',
            data: data.dataY,
            borderWidth: 2,
            backgroundColor: data.dataX.map((label) => this.getColor(label)),
            borderColor: data.dataX.map((label) => this.getColor(label)),
            borderRadius: 1,
          },
        ],
      },
      options: {
        aspectRatio: 2,
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            align: 'start',
            anchor: 'end',
            formatter: function (value) {
              return value + '%';
            },
          },
        },
        indexAxis: 'y',
        scales: {
          y: {
            display: false,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  };

  bondsAmountInTimePayors(data: IDataSet) {
    const chartExist = Chart.getChart('payorReimbursementInTime');
    if (chartExist != undefined) chartExist.destroy();
    Chart.register(...registerables);

    if (data.dataY2 === undefined) return;

    const datasets = data.dataY2.map((label, i) => ({
      label: label,
      data: data.dataY[i],
      backgroundColor: this.getColor(label as string),
      borderColor: this.getColor(label as string),
    }));

    const config = {
      type: 'bar',
      data: {
        labels: data.dataX,
        datasets: datasets as any,
      },
      options: {
        scales: {
          x: {
            stacked: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y: {
            ticks: {
              precision: 0,
            },
            beginAtZero: true,
            stacked: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                return (
                  label + ': ' + formatNumberToMillionsClp(context.parsed.y, 1)
                );
              },
            },
          },
        },
      },
    };

    new Chart('payorReimbursementInTime', config);
  }

  bondsQuantityAndAverageAmountByWeek = (data: IDataSet) => {
    const chartExist = Chart.getChart('bondsWeeklyTendency');
    if (chartExist != undefined) chartExist.destroy();

    const maxValue = Math.max(Number(...data.dataY));
    if (data.dataY2 === undefined) return;
    const maxValue2 = Math.max(Number(...data.dataY2));

    Chart.register(...registerables);

    const chart = new Chart('bondsWeeklyTendency', {
      type: 'bar',
      data: {
        labels: data.dataX,
        datasets: [
          {
            label: 'Bonos Semanales',
            data: data.dataY,
            backgroundColor: 'rgba(29,67,136,255)',
            borderColor: 'rgba(29,67,136,255)',
            borderWidth: 1,
            order: 2,
            yAxisID: 'y1',
            datalabels: {
              formatter: function (value) {
                return formatNumberToMillionsClp(value, 0);
              },
              align: 'start',
              anchor: 'end',
            },
          },
          {
            label: 'Ticket Promedio',
            data: data.dataY2,
            type: 'line',
            fill: false,
            backgroundColor: 'rgba(236,73,104,255)',
            borderColor: 'rgba(236,73,104,255)',
            borderWidth: 4,
            order: 1,
            yAxisID: 'y2',
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: 'rgba(236,73,104,255)',
            },
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: 'category',
            display: true,
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y1: {
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              callback: function (value, index) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
          },
          y2: {
            type: 'linear',
            display: true,
            position: 'right',
            suggestedMax: maxValue2 + 5,
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              callback: function (value, index) {
                return formatNumberToMillionsClp(value, 0);
              },
              color: 'rgba(236,73,104,255)',
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                const yAxisID = context.dataset.yAxisID;
                if (yAxisID === 'y2')
                  return (
                    label +
                    ': ' +
                    formatNumberToMillionsClp(context.parsed.y, 1)
                  );
                return label + ': ' + context.parsed.y;
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  };

  reimbursementAmountAndPercentageByWeek = (data: IDataSet) => {
    const chartExist = Chart.getChart('ReimbursementAmountAndPercentageByWeek');
    if (chartExist != undefined) chartExist.destroy();

    Chart.register(...registerables);

    const chart = new Chart('ReimbursementAmountAndPercentageByWeek', {
      type: 'bar',
      data: {
        labels: data.dataX,
        datasets: [
          {
            label: 'Monto Bonificado',
            data: data.dataY,
            backgroundColor: 'rgba(29,67,136,255)',
            borderColor: 'rgba(29,67,136,255)',
            borderWidth: 1,
            order: 2,
            yAxisID: 'y1',
            datalabels: {
              formatter: function (value) {
                return formatNumberToMillionsClp(value, 1);
              },
              align: 'start',
              anchor: 'end',
            },
          },
          {
            label: 'Cobertura Promedio',
            data: data.dataY2,
            type: 'line',
            fill: false,
            backgroundColor: 'rgba(236,73,104,255)',
            borderColor: 'rgba(236,73,104,255)',
            borderWidth: 4,
            order: 1,
            yAxisID: 'y2',
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: 'rgba(236,73,104,255)',
              formatter: function (value) {
                return value + '%';
              },
            },
          },
        ],
      },
      options: {
        scales: {
          x: {
            type: 'category',
            display: true,
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y1: {
            ticks: {
              callback: function (value, index) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
            type: 'linear',
            display: true,
            position: 'left',
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            position: 'right',
            suggestedMax: 100,
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              color: 'rgba(236,73,104,255)',
              callback: function (value, index, values) {
                return value + '%';
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            align: 'end',
            anchor: 'start',
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                const yAxisID = context.dataset.yAxisID;
                if (yAxisID === 'y1')
                  return (
                    label +
                    ': ' +
                    formatNumberToMillionsClp(context.parsed.y, 1)
                  );
                return label + ': ' + context.parsed.y;
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  };

  bondsCountInTimePayors(data: IDataSet) {
    const chartExist = Chart.getChart('bondCountPerPayorPerDate');
    if (chartExist != undefined) chartExist.destroy();
    Chart.register(...registerables);

    if (data.dataY2 === undefined) return;

    const datasets = data.dataY2.map((label, i) => ({
      label: label,
      data: data.dataY[i],
      backgroundColor: this.getColor(label as string),
      borderColor: this.getColor(label as string),
    }));

    const config = {
      type: 'bar',
      data: {
        labels: data.dataX,
        datasets: datasets as any,
      },
      options: {
        scales: {
          x: {
            stacked: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y: {
            ticks: {
              precision: 0,
            },
            beginAtZero: true,
            stacked: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
        },
      },
    };

    new Chart('bondCountPerPayorPerDate', config);
  }

  getColor(label: string) {
    const colorPalette = [
      'rgba(41,206,178,255)', // Green
      'rgba(252,98,64,255)', // Orange
      'rgba(16,155,241,255)', // Light Blue
      'rgba(19,85,204,255)', // Blue
      'rgba(236,73,104,255)', // Red
      'rgba(160,160,160, 255)', // Gray
    ];
    switch (label) {
      case 'Vida Tres':
        return 'rgba(252,98,64,255)';
      case 'Banmedica':
        return 'rgba(236,73,104,255)';
      case 'Consalud':
        return 'rgba(41,206,178,255)';
      case 'Colmena':
        return 'rgba(19,85,204,255)';
      case 'Cruz Blanca':
        return 'rgba(16,155,241,255)';
      case 'Masvida':
        return 'rgba(160,160,160, 255)';
      default:
        return 'rgba(160, 160, 160, 255)';
    }
  }
}

import { Component, OnInit } from '@angular/core';
import { IDailyReports } from 'src/app/models/database.model';
import { DatabaseService } from 'src/app/services/database.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private databaseService: DatabaseService,
    public authService: AuthService
  ) {}

  ngOnInit(): void {}
}

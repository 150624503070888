import { Component, OnDestroy, OnInit } from '@angular/core';
import { DashboardService } from '../../services/dashboard.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-info-cards',
  templateUrl: './info-cards.component.html',
  styleUrls: ['./info-cards.component.scss'],
})
export class InfoCardsComponent implements OnInit, OnDestroy {
  public dashBoardData$ = this.dashboardService.dashBoardData$;
  private destroy$ = new Subject<void>();

  constructor(public dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.dashBoardData$.pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

<div class="header bg-blue-light-finapsys pb-1">
  <div class="container-fluid">
    <div class="header-body">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-0">
            <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
              <li class="breadcrumb-item">
                <a class="h2 nav-link-text">Dashboard</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="">
    <app-info-cards></app-info-cards>
  </div>
</div>
<div class="loaded-content"></div>
<div class="main-content-item loaded-content" id="bonosChart">
  <app-bonos-chart></app-bonos-chart>
</div>
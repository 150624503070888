import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user.service';
import { authErrors } from 'src/app/errors/auth';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  public loading = false;
  public needVerification = false;
  public showError = false;
  public verifyCode: string = '';
  public verificationId: string = '';
  public multiFactorResolver: any;
  public verifyMessage: string = '';
  constructor(
    public authService: AuthService,
    public router: Router,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    console.log({
      prod: environment.production,
      emu: environment.useEmulators,
    });
  }

  async signIn(email: string, password: string) {
    this.loading = true;
    await this.authService
      .SignIn(email, password)
      .then(() => {})
      .catch(async (error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          this.twoFactorHandling(error);
          return;
        }
        this.authService.ShowError(error.code);
        this.loading = false;
      });
  }

  async signInWithGoogle() {
    this.loading = true;
    await this.authService
      .GoogleAuth()
      .then(() => {})
      .catch(async (error) => {
        if (error.code === 'auth/multi-factor-auth-required') {
          this.twoFactorHandling(error);
          return;
        }
        this.authService.ShowError(`google-${error.code}`);
        this.loading = false;
      });
  }

  async verify(verificationCode: string) {
    this.loading = true;
    this.showError = false;
    await this.authService
      .verifyTwoFactorCode({
        verificationId: this.verificationId,
        verificationCode,
        resolver: this.multiFactorResolver,
      })
      .catch((error) => {
        this.verifyMessage =
          error.code in authErrors
            ? authErrors[error.code]
            : authErrors['default'];
        this.loading = false;
        this.showError = true;
      });
  }

  async twoFactorHandling(error: any) {
    this.needVerification = true;
    const result = await this.authService.twoFactorHandling(error);
    this.verificationId = result.verificationId;
    this.multiFactorResolver = result.resolver;
    this.loading = false;
    this.verifyMessage = `Ingresa el código enviado al número ${this.multiFactorResolver.hints[0].phoneNumber}, si no te llega en los próximos minutos intenta nuevamente.`;
  }

  reload() {
    window.location.reload();
  }
}

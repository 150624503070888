import { Injectable } from '@angular/core';
import { ExtractBondDataInput } from '../components/admin/admin.component';
import { RequestService } from './request.service';
import { IDashboardData } from '../components/dashboard/dashboard.component';

interface ICallDashboardDataInput {
  uid: string;
  organizationId: string;
  impersonateToken?: string;
}
@Injectable({
  providedIn: 'root',
})
export class FunctionsService {
  constructor(private requestService: RequestService) {}

  // This service will be deprecated soon. All calls are being moved to request.service.ts

  callEcryptText({ text }: { text: string }) {
    return this.requestService.callFunction({
      functionName: 'callableEncryptText',
      params: { text },
    });
  }

  callBondDataExtraction(bondIds: ExtractBondDataInput) {
    return this.requestService.callFunction({
      functionName: 'callableBondDataExtraction',
      params: bondIds,
    });
  }

  callStartScraperProcess({
    start,
    end,
    save,
    orgId,
    payorId,
    checkMode,
    scraperTarget,
  }: {
    start: string;
    end: string;
    save: string;
    orgId: string;
    payorId: string;
    checkMode: string;
    scraperTarget: string;
  }) {
    return this.requestService.callFunction({
      functionName: 'callableStartScraperProcess',
      params: {
        start,
        end,
        save,
        orgId,
        payorId,
        checkMode,
        scraperTarget,
      },
    });
  }

  callDashboardData(
    uid: string,
    organizationId: string,
    impersonateToken?: string
  ) {
    const params: ICallDashboardDataInput = impersonateToken
      ? { uid, organizationId, impersonateToken }
      : { uid, organizationId };
    return this.requestService.callFunction({
      functionName: 'callableDashboardData',
      params: params,
    });
  }

  callFonasaDashboardData(
    uid: string,
    organizationId: string,
    impersonateToken?: string
  ) {
    return this.requestService.callFunction({
      functionName: 'callableFonasaDashboardData',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callFonasaDailyPaymentsDashboardData(
    uid: string,
    organizationId: string,
    impersonateToken?: string
  ) {
    return this.requestService.callFunction({
      functionName: 'callableFonasaPaymentGroupsDashboardData',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callFonasaDownloadDatabase(
    uid: string,
    organizationId: string,
    impersonateToken?: string
  ) {
    return this.requestService.callFunction({
      functionName: 'callableGetFonasaClaimsDatabase',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callFonasaPaymentGroupsDownloadDatabase(
    uid: string,
    organizationId: string,
    impersonateToken?: string
  ) {
    return this.requestService.callFunction({
      functionName: 'callableGetFonasaPaymentGroupsDatabase',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callGenerateImpersonateToken(target: string) {
    return this.requestService.callFunction({
      functionName: 'callableGenerateImpersonateToken',
      params: { target },
    });
  }

  callGetOrganizationBondsDailyReports({
    uid,
    organizationId,
    impersonateToken,
  }: {
    uid: string;
    organizationId: string;
    impersonateToken?: string;
  }) {
    return this.requestService.callFunction({
      functionName: 'callableGetOrganizationBondsDailyReports',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callGetOrganizationFonasaDailyReports({
    uid,
    organizationId,
    impersonateToken,
  }: {
    uid: string;
    organizationId: string;
    impersonateToken?: string;
  }) {
    return this.requestService.callFunction({
      functionName: 'callableGetOrganizationFonasaDailyReports',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callGetOrganizationDenialsDailyReports({
    uid,
    organizationId,
    impersonateToken,
  }: {
    uid: string;
    organizationId: string;
    impersonateToken?: string;
  }) {
    return this.requestService.callFunction({
      functionName: 'callableGetOrganizationDenialsDailyReports',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callGetReportFileByPath({
    docId,
    file,
    uid,
    organizationId,
    impersonateToken,
  }: {
    docId: string;
    file: string;
    uid: string;
    organizationId: string;
    impersonateToken?: string;
  }) {
    return this.requestService.callFunction({
      functionName: 'callableGetReportFileByPath',
      params: impersonateToken
        ? { docId, file, uid, organizationId, impersonateToken }
        : { docId, file, uid, organizationId },
    });
  }

  callGetFileByPath({ filePath }: { filePath: string }) {
    return this.requestService.callFunction({
      functionName: 'callableGetFileByPath',
      params: { filePath },
    });
  }

  callGetAdminData() {
    return this.requestService.callFunction({
      functionName: 'callableGetAdminData',
      params: {},
    });
  }

  callStartReportProcess({ date, orgId }: { date: string; orgId: string }) {
    return this.requestService.callFunction({
      functionName: 'callableStartReportProcess',
      params: { date, orgId },
    });
  }

  callUpdateDailyReportsDownloaded(
    uid: string,
    organizationId: string,
    reportId: string
  ) {
    return this.requestService.callFunction({
      functionName: 'callableUpdateReportDownloaded',
      params: { uid, organizationId, reportId },
    });
  }

  callSendDailyAlert({ date }: { date: string }) {
    return this.requestService.callFunction({
      functionName: 'callableSendDailyAlert',
      params: { date },
    });
  }

  callClaimsStockDownloadDatabase(
    uid: string,
    organizationId: string,
    impersonateToken?: string
  ) {
    return this.requestService.callFunction({
      functionName: 'callableGetClaimsStockDatabase',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callClaimsStockDashboardData(
    uid: string,
    organizationId: string,
    impersonateToken?: string
  ) {
    return this.requestService.callFunction({
      functionName: 'callableClaimsStockDashboardData',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }
  callGetOrganizationClaimsDailyReports({
    uid,
    organizationId,
    impersonateToken,
  }: {
    uid: string;
    organizationId: string;
    impersonateToken?: string;
  }) {
    return this.requestService.callFunction({
      functionName: 'callableGetOrganizationClaimsDailyReports',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }

  callGetOrganizationClaimsPayors({
    uid,
    organizationId,
    impersonateToken,
  }: {
    uid: string;
    organizationId: string;
    impersonateToken?: string;
  }) {
    return this.requestService.callFunction({
      functionName: 'callableGetOrganizationClaimsPayors',
      params: impersonateToken
        ? { uid, organizationId, impersonateToken }
        : { uid, organizationId },
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { Chart, registerables } from 'chart.js';
import { FunctionsService } from 'src/app/services/functions.service';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { formatNumberToMillionsClp } from '../constants';

type IClaimsStockDashboardData = {
  claimsStock: {
    dataSets: {
      general: {
        name: string;
        dataX: string[];
        dataY: number[];
        dataY2: number[];
      };
    };
  };
};

@Component({
  selector: 'app-claims-stock',
  templateUrl: './claims-stock.component.html',
})
export class ClaimsStockComponent implements OnInit {
  showAccountStockModule = true;
  activeOrganization = '';
  uid = '';
  loading = false;
  loadingDownload = false;
  token = '';
  payors = [];
  constructor(
    private userService: UserService,
    private functionService: FunctionsService,
    private analyticsService: AnalyticsService
  ) {
    this.userService.uid.subscribe((uid) => (this.uid = uid));
    this.userService.token.subscribe((token) => (this.token = token));
    this.userService.activeOrganization.subscribe((organization) => {
      this.activeOrganization = organization;
      if (this.activeOrganization && this.uid)
        this.getOrganizationClaimsPayors();
    });
  }

  ngOnInit(): void {
    this.showClaimsStockDashboardChart();
    this.getOrganizationClaimsPayors();
  }

  downloadClaimsStockDatabase() {
    this.analyticsService.logEvent(
      this.analyticsService.events.download_claims_stocks_database,
      {
        type: 'isapre-claims-database',
      }
    );
    this.loadingDownload = true;
    if (!this.uid) throw new Error('No uid');
    this.functionService
      .callClaimsStockDownloadDatabase(
        this.uid,
        this.activeOrganization,
        this.token
      )
      .subscribe((url: string) => {
        this.loadingDownload = false;
        window.open(url);
      });
  }

  getOrganizationClaimsPayors() {
    this.functionService
      .callGetOrganizationClaimsPayors({
        uid: this.uid,
        organizationId: this.activeOrganization,
        impersonateToken: this.token,
      })
      .subscribe((payors) => {
        this.payors = payors;
      });
  }

  showClaimsStockDashboardChart() {
    this.loading = true;
    if (!this.uid) throw new Error('No uid');
    Chart.defaults.color = 'white';
    this.functionService
      .callClaimsStockDashboardData(
        this.uid,
        this.activeOrganization,
        this.token
      )
      .subscribe((claimsStockDashboardData: IClaimsStockDashboardData) => {
        this.loading = false;
        this.showClaimsStockDasboardChart(claimsStockDashboardData);
      });
  }

  showClaimsStockDasboardChart(
    claimsStockDashboardData: IClaimsStockDashboardData
  ) {
    Chart.getChart('claimsStockDashboardChart');
    Chart.register(...registerables);
    const chart = new Chart('claimsStockDashboardChart', {
      type: 'bar',
      data: {
        labels: claimsStockDashboardData.claimsStock.dataSets.general.dataX,
        datasets: [
          {
            label: 'Cantidad de Cuentas (#)',
            data: claimsStockDashboardData.claimsStock.dataSets.general.dataY2,
            borderWidth: 2,
            borderRadius: 1,
            yAxisID: 'y1',
            datalabels: {
              align: 'start',
              anchor: 'end',
            },
          },
          {
            label: 'Monto total ($CLP millones)',
            data: claimsStockDashboardData.claimsStock.dataSets.general.dataY,
            borderWidth: 2,
            type: 'line',
            yAxisID: 'y2',
            datalabels: {
              align: 'end',
              anchor: 'end',
              color: 'rgba(236,73,104,255)',
            },
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: true,
          },
          datalabels: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context: any) {
                const label = context.dataset.label || '';
                const yAxisID = context.dataset.yAxisID;
                if (yAxisID === 'y2')
                  return (
                    label +
                    ': ' +
                    formatNumberToMillionsClp(context.parsed.y, 1)
                  );
                return label + ': ' + context.parsed.y;
              },
            },
          },
        },
        indexAxis: 'x',
        scales: {
          x: {
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y1: {
            beginAtZero: true,
            grid: {
              drawOnChartArea: false,
              display: false,
            },
          },
          y2: {
            type: 'linear',
            display: true,
            position: 'right',
            title: {
              display: true,
            },
            grid: {
              drawOnChartArea: false,
              display: false,
            },
            ticks: {
              color: 'rgba(236,73,104,255)',
              callback: function (value, index) {
                return formatNumberToMillionsClp(value, 0);
              },
            },
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }
}

<nav class="navbar navbar-vertical fixed-left navbar-expand-xs navbar-light" id="sidenav-main"
  style="background-color: white">
  <div class="side-nav-content" id="sidenav-menu">
    <!-- Brand -->
    <div class="sidenav-header d-flex align-items-center">
      <a class="navbar-brand" href="../../../index.html">
        <img src="../../assets/img/brand/logo-finapsys-white-bg.png" class="navbar-brand-img" alt="..." />
      </a>
      <!-- /assets/vagus-logo.png -->
    </div>
    <div class="center-header">
      <li *ngIf="userOrganizations.length > 1" class="nav-item dropdown">
        <a href="#" class="nav-link side-nav-element" data-toggle="dropdown" role="button">
          <span class="nav-link-inner-text">{{ activeOrganizationName }} </span>
          <i class="ml-1 fas fa-chevron-down nav-link-arrow"></i>
        </a>
        <ul class="dropdown-menu org-selector bg-primary">
          <a *ngFor="let organization of userOrganizations" class="dropdown-item"
            (click)="changeOrganization(organization)">
            <i *ngIf="activeOrganization == organization.organizationId" class="ni ni-check-bold"></i>
            <i *ngIf="activeOrganization != organization.organizationId" class="ni ni-building"></i>
            <span class="dropdown-text">{{ organization.name }}</span>
          </a>
        </ul>
      </li>
    </div>
    <div class="navbar-inner">
      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidenav-collapse-main">
        <!-- Nav items -->
        <ul class="navbar-nav">
          <li class="nav-item btn-outline" (click)="loadDashboard()">
            <a class="nav-link active" href="#navbar-dashboards" data-toggle="collapse" role="button"
              aria-expanded="true" aria-controls="navbar-dashboards"><i class="ni ni-chart-bar-32"></i>
              <span class="nav-link-text">Dashboard</span></a>
          </li>
          <li class="nav-item" (click)="showPayorsSubitems = !showPayorsSubitems">
            <a class="nav-link active" data-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="navbar-dashboards"><i class="ni ni-folder-17"></i>
              <span class="nav-link-text">Isapres</span></a>
          </li>
          <a class="nav-subitem" *ngIf="showPayorsSubitems" (click)="loadBonos()">
            Bonos de Isapres
          </a>
          <a class="nav-subitem" *ngIf="showPayorsSubitems && (showDenialsModule === true || this.userAdmin === true)"
            (click)="loadDenials()">
            Cuentas Devueltas
          </a>
          <a class="nav-subitem" *ngIf="showPayorsSubitems && (showFollowUpModule === true || this.userAdmin === true)"
            (click)="loadFollowUp()">
            Cuentas Vencidas
          </a>
          <a class="nav-subitem"
            *ngIf="showPayorsSubitems && (showClaimsStockModule === true || this.userAdmin === true)"
            (click)="loadClaimsStock()">
            Cuentas Pendientes
          </a>
          <li *ngIf="showFonasaModule || this.userAdmin === true" class="nav-item"
            (click)="showFonasaSubitems = !showFonasaSubitems">
            <a class="nav-link active" data-toggle="collapse" role="button" aria-expanded="true"
              aria-controls="navbar-dashboards"><i class="ni ni-single-copy-04"></i>
              <span class="nav-link-text">Fonasa</span></a>
          </li>
          <div *ngIf="showFonasaSubitems" class="nav-subitem-title">Mis Casos</div>
          <a class="nav-subitem" *ngIf="showFonasaSubitems" (click)="loadFonasaDashboard()">
            Resumen de casos
          </a>
          <a class="nav-subitem" *ngIf="showFonasaSubitems" (click)="loadFonasaDailyCases()">
            Cambios de estado
          </a>
          <div *ngIf="showFonasaSubitems" class="nav-subitem-title">Mis Pagos</div>
          <a class="nav-subitem" *ngIf="showFonasaSubitems" (click)="loadFonasaPaymentGroupsDashboard()">
            Resumen de pagos
          </a>
          <a class="nav-subitem" *ngIf="showFonasaSubitems" (click)="loadFonasaPaymentGroupsDailyChanges()">
            Cambios de estado
          </a>
          <li class="nav-item" (click)="loadAdmin()" *ngIf="this.userAdmin === true && this.organizationAdmin === true">
            <a class="nav-link active" href="#navbar-dashboards" data-toggle="collapse" role="button"
              aria-expanded="true" aria-controls="navbar-dashboards"><i class="ni ni-ui-04"></i>
              <span class="nav-link-text">Admin</span></a>
          </li>
          <hr class="my-3" />
        </ul>
        <div class="version">
          <p>v{{ currentVersion }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center side-nav-footer">
    <h4 clas>Soporte</h4>
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
      <path
        d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" />
    </svg>
    <h6>soporte@finapsys.cl</h6>
    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
      <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
      <path
        d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
    </svg>
    <h6>+562 2914 5207</h6>
  </div>
</nav>
<div class="main-content">
  <div class="dashboard-view">
    <app-dashboard *ngIf="dashBoardView"></app-dashboard>
  </div>
  <div class="admin-view">
    <app-admin *ngIf="adminView"></app-admin>
  </div>
  <div class="bond-view">
    <app-bonos *ngIf="bonosView"></app-bonos>
  </div>
  <div class="fonasa-view">
    <app-fonasa-daily-cases *ngIf="fonasaDailyCasesView"></app-fonasa-daily-cases>
  </div>
  <div class="fonasa-view">
    <app-fonasa-dashboard *ngIf="fonasaDashboardView"></app-fonasa-dashboard>
  </div>
  <div class="denials-view">
    <app-denials *ngIf="denialsView"></app-denials>
  </div>
  <div class="fonasa-view">
    <app-fonasa-daily-payment-groups *ngIf="getFonasaPaymentGroupsDailyChanges"></app-fonasa-daily-payment-groups>
  </div>
  <div class="fonasa-view">
    <app-fonasa-daily-payments-dashboard *ngIf="getFonasaPaymentGroupsDashboard"></app-fonasa-daily-payments-dashboard>
  </div>
  <div class="claims-view">
    <app-claims-stock *ngIf="claimsStockView"></app-claims-stock>
  </div>
  <div class="follow-up-view">
    <app-follow-up *ngIf="followUpView"></app-follow-up>
  </div>
</div>
<div>
  <div class="pb-8 header bg-blue-light-finapsys">
    <div class="container-fluid">
      <div class="header-body">
        <div class="py-4 row align-items-center">
          <div class="col-lg-6 col-7">
            <nav
              aria-label="breadcrumb"
              class="d-none d-md-inline-block ml-md-0"
            >
              <ol class="breadcrumb breadcrumb-links breadcrumb-dark">
                <li class="breadcrumb-item">
                  <a class="h2 nav-link-text">Cuentas Pendientes</a>
                </li>
              </ol>
            </nav>
          </div>
          <div class="col-lg-4 col-7"></div>
          <div class="col-lg-2 col-7">
            <a type="button" style="width: inherit">
              <button
                (click)="downloadClaimsStockDatabase()"
                *ngIf="!loadingDownload"
                type="button"
                class="btn btn-outline"
                style="
                  background-color: white;
                  display: flex;
                  align-items: center;
                  height: 50px;
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="23"
                  fill="green"
                  class="bi bi-file-earmark-excel"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M5.884 6.68a.5.5 0 1 0-.768.64L7.349 10l-2.233 2.68a.5.5 0 0 0 .768.64L8 10.781l2.116 2.54a.5.5 0 0 0 .768-.641L8.651 10l2.233-2.68a.5.5 0 0 0-.768-.64L8 9.219l-2.116-2.54z"
                  ></path>
                  <path
                    d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"
                  ></path>
                </svg>
                <p
                  style="
                    color: black;
                    margin-bottom: 0px;
                    font-size: 12px;
                    font-weight: 400;
                  "
                >
                  Descargar Cuentas
                </p>
              </button>
              <div
                *ngIf="loadingDownload"
                style="
                  background-color: white;
                  display: flex;
                  align-items: center;
                  border-radius: 5px;
                  padding: 10px;
                  width: auto;
                  justify-content: center;
                "
              >
                <img
                  src="../../../assets/ball-triangle.svg"
                  style="height: 30px"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container-fluid mt--8">
  <div class="main-content" id="panel">
    <!-- Topnav -->

    <!-- Page content -->
    <div class="container-fluid mt--6">
      <div class="row">
        <!-- Light table -->
        <div class="col">
          <div class="card">
            <!-- Card header -->
            <div
              class="border-0 card-header"
              *ngIf="showAccountStockModule !== true"
            >
              <p class="mb-0 text-gray">Tu organización no tiene activo el modulo de stock de cuentas. Pidele al administrador de tu organización que se contacte con el equipo de Finapsys para hacerlo.</p>
            </div>
          </div>
          <div *ngIf="showAccountStockModule === true" data-toggle="list">
            <div class="card bg-charts" style="position: relative">
              <div
                style="position: absolute; top: 50%; right: 45%"
                *ngIf="loading"
              >
                <img src="../../../assets/ball-triangle.svg" />
              </div>
              <div class="card-header bg-transparent">
                <div class="row align-items-center">
                  <div class="col">
                    <h3 class="h3 text-white mb-0 nav-link-text">
                      Resumen de Cuentas Pendientes Según Vencimiento
                    </h3>
                    <h4 class="h4 text-white mb-0">{{
                      payors.length > 0 ? 'Incluye Isapre' : ''}}{{
                      payors.length > 1 ?
                      's ' + payors.slice(0, -1).join(',') +' y ' + payors.at(-1) :
                      ' ' + payors.join(',')}}</h4>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="chart">
                  <canvas
                    id="claimsStockDashboardChart"
                    class="chart-canvas"
                    style="max-height: 300px"
                  ></canvas>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { PERSISTENCE } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import {
  provideAnalytics,
  getAnalytics,
  ScreenTrackingService,
  UserTrackingService,
} from '@angular/fire/analytics';
import {
  provideAppCheck,
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from '@angular/fire/app-check';

import { HomeComponent } from './components/home/home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { AuthService } from './services/auth.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AdminComponent } from './components/admin/admin.component';
import { HttpClientModule } from '@angular/common/http';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { USE_EMULATOR as USE_STORAGE_EMULATOR } from '@angular/fire/compat/storage';
import { BonosComponent } from './components/bonos/bonos.component';
import { DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { BonosChartComponent } from './components/bonos-chart/bonos-chart.component';
import { InfoCardsComponent } from './components/info-cards/info-cards.component';
import { AppCheckComponent } from './components/app-check/app-check.component';
import { denialsComponent } from './components/denials/denials.component';
import { FonasaDailyCasesComponent } from './components/fonasa-daily-cases/fonasa-daily-cases.component';
import { FonasaDashboardComponent } from './components/fonasa-dashboard/fonasa-dashboard.component';
import { FonasaDailyPaymentGroupsComponent } from './components/fonasa-daily-payment-groups/fonasa-daily-payment-groups.component';
import { FonasaDailyPaymentsDashboardComponent } from './components/fonasa-daily-payments-dashboard/fonasa-daily-payments-dashboard.component';
import * as Sentry from '@sentry/angular-ivy';
import { ClaimsStockComponent } from './components/claims-stock/claims-stock.component';
import { FollowUpComponent } from './components/follow-up/follow-up.component';

declare global {
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}
self.FIREBASE_APPCHECK_DEBUG_TOKEN = environment.firebase.appCheckDebugToken;
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SignInComponent,
    ForgotPasswordComponent,
    AdminComponent,
    BonosComponent,
    SideNavComponent,
    TopNavComponent,
    DashboardComponent,
    BonosChartComponent,
    InfoCardsComponent,
    AppCheckComponent,
    denialsComponent,
    FonasaDailyCasesComponent,
    FonasaDashboardComponent,
    FonasaDailyPaymentGroupsComponent,
    FonasaDailyPaymentsDashboardComponent,
    ClaimsStockComponent,
    FollowUpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => getFunctions()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    provideAppCheck(() =>
      initializeAppCheck(undefined, {
        provider: new ReCaptchaEnterpriseProvider(environment.firebase.rechaptchaToken),
        isTokenAutoRefreshEnabled: true,
      })
    ),
    NgbModule,
    AngularFireModule.initializeApp(environment.firebase),
    HttpClientModule,
    FormsModule,
  ],
  providers: [
    AuthService,
    DatePipe,
    ScreenTrackingService,
    UserTrackingService,
    {
      provide: USE_AUTH_EMULATOR,
      useValue: environment.useEmulators
        ? ['http://localhost:9099']
        : undefined,
    },
    {
      provide: USE_DATABASE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 9000] : undefined,
    },
    {
      provide: USE_FIRESTORE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 8080] : undefined,
    },
    {
      provide: USE_FUNCTIONS_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 5001] : undefined,
    },
    {
      provide: USE_STORAGE_EMULATOR,
      useValue: environment.useEmulators ? ['localhost', 9199] : undefined,
    },
    { provide: PERSISTENCE, useValue: 'session' },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

<body class="bg-white">
  <!-- Main content -->
  <div class="main-content">
    <!-- Header -->
    <div class="header bg-primary py-7 py-lg-8 pt-lg-1">
      <div class="container">
        <div class="text-center header-body mb-7">
          <div class="row justify-content-center">
            <div class="px-5 col-xl-5 col-lg-6 col-md-8">
              <div class="mb-4">
                <img src="../../assets/img/brand/logo-finapsys-blue-bg.png" class="navbar-brand-img" alt="..."
                  width="50%" />
              </div>
              <p class="text-white text-lead">
                La transformación digital del Ciclo de Ingreso
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <div class="container pb-5 mt--9 text-gray">
      <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
          <div class="mb-0 border card bg-secondary border-soft">
            <div class="card-body px-lg-5 py-lg-5">
              <form role="form">
                <div *ngIf="!this.needVerification">
                  <div class="mb-4 text-center">
                    <small>Ingresa con tus credenciales</small>
                  </div>
                  <div class="mb-3 form-group">
                    <div class="input-group input-group-merge input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                      </div>
                      <input class="form-control" placeholder="Email" type="email" #userName required />
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="input-group input-group-merge input-group-alternative">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                      </div>
                      <input class="form-control" placeholder="Password" type="password" #userPassword required />
                    </div>
                  </div>
                  <div class="text-center" *ngIf="!this.needVerification">
                    <button type="button" class="my-4 btn bg-blue-light-finapsys"
                      (click)="this.signIn(userName.value, userPassword.value)">
                      Ingresar
                    </button>
                  </div>
                </div>
                <div class="form-group" *ngIf="this.needVerification">
                  <div class="input-group input-group-merge input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                    </div>
                    <input class="form-control" placeholder="Código de verificación" type="string" #verificationCode
                      required />
                  </div>
                  <div class="text-center">
                    <button *ngIf="!this.loading" type="button" class="my-4 btn bg-blue-light-finapsys"
                      (click)="this.verify(verificationCode.value)">
                      Verificar
                    </button>
                  </div>
                  <div class="text-center" *ngIf="!this.loading" [ngClass]="{ 'error-message': showError }">
                    {{ this.verifyMessage }}
                    <div>
                      <a (click)="this.reload()" class="cancel-link">Cancelar</a>
                    </div>
                  </div>
                  <div class="text-center" *ngIf="this.loading">
                    <div class="loader"></div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="btn-container">
            <div class="google-btn" (click)="this.signInWithGoogle()" *ngIf="!this.needVerification">
              <div class="google-icon-wrapper">
                <img class="google-icon"
                  src="https://storage.googleapis.com/vagus-tech-production-public/logos/google-logo.png" />
              </div>
              <p class="btn-text"><b>Entrar con Google</b></p>
            </div>
            <div class="mt-3 row">
              <div>
                <a (click)="this.router.navigate(['/forgot-password'])" class="text-gray"><small>Olvidaste tu contraseña?</small></a>
              </div>
            </div>
          </div>
          <app-app-check></app-app-check>
        </div>
      </div>
    </div>
  </div>
</body>

import { Injectable } from '@angular/core';
import { getAnalytics, setUserId, logEvent } from 'firebase/analytics';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analytics: any;
  public events = {
    loginAttempt: 'login_attempt',
    loginSuccessful: 'login_successful',
    logout: 'logout',
    forgotPassword: 'forgot_password',
    download_resumen_excel: 'download_resumen_excel',
    download_bonds_data_excel: 'download_bonds_data_excel',
    download_fonasa_database: 'download_fonasa_database',
    download_fonasa_changes: 'download_fonasa_changes',
    download_denials_excel: 'download_denials_excel',
    download_follow_up_excel: 'download_follow_up_excel',
    download_zip: 'download_zip',
    download_claims_stocks_database: 'download_claims_stocks_database',
  };

  constructor() {
    this.analytics = getAnalytics();
  }

  setAnalitics(userId: string) {
    setUserId(this.analytics, userId);
  }

  logEvent(name: string, params: Record<string, any> = {}) {
    logEvent(this.analytics, name, params);
  }
}

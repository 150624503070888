import { Component } from '@angular/core';
import { DatabaseService } from 'src/app/services/database.service';
import { UserService } from 'src/app/services/user.service';
import { FunctionsService } from 'src/app/services/functions.service';
import {
  IOrganization,
  IsapreClaimsReport,
} from 'src/app/models/database.model';
import { IUserOrganizations } from '../bonos/bonos.component';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { map } from 'rxjs';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-denials',
  templateUrl: './denials.component.html',
  styleUrls: ['./denials.component.scss'],
})
export class denialsComponent {
  public userAdmin = false;
  dailyreports: IsapreClaimsReport[] = [];
  loading = false;
  activeOrganization = '';
  currentOrganization = {} as IOrganization;
  uid = '';
  organizationId = '';
  token = '';
  payors = [];
  showDenialsModule = false;
  constructor(
    private databaseService: DatabaseService,
    public userService: UserService,
    private functionsService: FunctionsService,
    private analyticsService: AnalyticsService
  ) {
    this.userService.uid.subscribe((uid) => (this.uid = uid));
    this.userService.isAdmin.subscribe((isAdmin) => (this.userAdmin = isAdmin));
    this.userService.activeOrganization.subscribe((organization) => {
      this.activeOrganization = organization;
      this.setOrganizationsOfLoggedUserAndGetBonosDailyReports();
    });
    this.userService.token.subscribe((token) => (this.token = token));
  }

  ngOnInit(): void {
    this.setOrganizationsOfLoggedUserAndGetBonosDailyReports();
    this.getOrganizationClaimsPayors();
  }

  setOrganizationsOfLoggedUserAndGetBonosDailyReports() {
    this.loading = true;
    this.getUserInformationForReports().subscribe((user) => {
      this.getBonosDailyReports();
      this.databaseService
        .getOrganizationDocument(this.activeOrganization)
        .subscribe((organization) => {
          this.currentOrganization = organization;
          this.databaseService
            .getOrganizationSettingsDocument(this.activeOrganization)
            .subscribe((settings) => {
              this.showDenialsModule = settings.products.denials.showModule;
            });
        });
    });
  }

  getUserInformationForReports() {
    return this.databaseService.getUserDocument(this.uid).pipe(
      map((user): IUserOrganizations => {
        return {
          name: user.email,
          uid: user.id || '',
          organizations: user.organizations,
        };
      })
    );
  }

  getBonosDailyReports() {
    this.functionsService
      .callGetOrganizationDenialsDailyReports({
        organizationId: this.activeOrganization,
        uid: this.uid,
        impersonateToken: this.token,
      })
      .subscribe((data) => {
        this.loading = false;
        this.dailyreports = data
          .sort(
            (a: IsapreClaimsReport, b: IsapreClaimsReport) =>
              b.reportDate._seconds - a.reportDate._seconds
          )
          .map((report: IsapreClaimsReport) => {
            const dateToShow = DateTime.fromSeconds(report.reportDate._seconds)
              .setZone('America/Santiago')
              .toFormat('dd-MM-yyyy');

            return {
              id: report.id,
              reportDate: dateToShow,
              numberOfClaims: report.numberOfClaims,
              reportPath: report.storagePath,
            };
          });
      });
  }

  downloadBondsDataExtracted(item: IsapreClaimsReport) {
    this.analyticsService.logEvent(
      this.analyticsService.events.download_denials_excel,
      {
        type: 'denials',
        ...item,
      }
    );
    this.functionsService
      .callGetReportFileByPath({
        docId: item.id,
        file: 'denials',
        uid: this.uid,
        organizationId: this.activeOrganization,
        impersonateToken: this.token,
      })
      .subscribe((url) => {
        window.location.assign(url);
      });
  }

  getOrganizationClaimsPayors() {
    this.functionsService
      .callGetOrganizationClaimsPayors({
        uid: this.uid,
        organizationId: this.activeOrganization,
        impersonateToken: this.token,
      })
      .subscribe((payors) => {
        this.payors = payors;
      });
  }
}

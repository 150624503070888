import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppCheck, getToken } from '@angular/fire/app-check';
import { BehaviorSubject, EMPTY, catchError, map, take } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  public appCheckToken = new BehaviorSubject('');

  constructor(
    private http: HttpClient,
    private appCheck: AppCheck,
    private authService: AuthService
  ) {
    this.updateAppCheckToken();
  }

  private updateAppCheckToken() {
    if (this.appCheck) {
      getToken(this.appCheck).then(({ token }) =>
        this.appCheckToken.next(token)
      );
    }
  }

  private getHeaders() {
    return {
      authorization: `Bearer ${this.authService.accessToken.value}`,
      'x-firebase-appcheck': this.appCheckToken.value,
      'cache-control': 'no-cache',
      'content-type': 'application/json',
    };
  }

  callFunction({
    functionName,
    params,
  }: {
    functionName: string;
    params: any;
  }) {
    const headers = new HttpHeaders(this.getHeaders());
    const body = { data: { params } };

    return this.http
      .post<any>(`${environment.apiUrl}/${functionName}`, body, { headers })
      .pipe(
        take(1),
        map((res) => res.result),
        catchError((err) => {
          if (err.status === 401) {
            this.authService.SignOut();
            return EMPTY;
          }
          throw err;
        })
      );
  }
}

<div id="recaptcha-container"></div>
<div *ngIf="userService.isLoggedIn(); then thenBlock; else elseBlock"></div>
<ng-template #thenBlock>
  <!-- Main content -->
  <div class="main-content" id="panel">
    <!-- Topnav -->
    <div class="top-nav">
      <app-top-nav></app-top-nav>
    </div>
    <!-- Side-nav -->
    <div class="side-nav">
      <app-side-nav></app-side-nav>
    </div>
    <!-- Page content -->
  </div>
</ng-template>
<ng-template #elseBlock>
  <router-outlet></router-outlet>
</ng-template>
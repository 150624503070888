import { Injectable } from '@angular/core';
import {
  MultiFactorResolver,
  PhoneAuthProvider,
  PhoneInfoOptions,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
} from '@angular/fire/auth';
import {
  authState,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut,
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AnalyticsService } from './analytics.service';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { authErrors } from '../errors/auth';
import { GoogleAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import * as Sentry from '@sentry/angular-ivy';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public accessToken = new BehaviorSubject('');

  constructor(
    public router: Router,
    public analyticsService: AnalyticsService
  ) {
    this.SubscribeToAuthState();
  }
  // Sign in with email/password
  async SignIn(email: string, password: string) {
    const auth = getAuth();
    await signInWithEmailAndPassword(auth, email, password).then((result) =>
      this.LogAttempt(result.user?.email)
    );
  }

  SubscribeToAuthState() {
    const auth = getAuth();
    authState(auth).subscribe((authState) => {
      if (!authState) this.SignOut();
      authState
        ?.getIdTokenResult()
        .then(({ token }) => this.accessToken.next(token ?? ''));
    });
  }

  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  AuthLogin(provider: GoogleAuthProvider) {
    const auth = getAuth();
    return signInWithPopup(auth, provider).then((result) =>
      this.LogAttempt(result.user?.email)
    );
  }

  LogAttempt(email: string | null) {
    this.setSentryUser(email);
    this.analyticsService.logEvent(
      this.analyticsService.events.loginSuccessful,
      { email: email }
    );
  }

  setSentryUser(email: string | null) {
    Sentry.setUser({ email: email ?? undefined });
  }

  ShowError(errorCode: string) {
    const alertMessage =
      errorCode in authErrors ? authErrors[errorCode] : authErrors['default'];
    window.alert(alertMessage);
  }

  ForgotPassword(passwordResetEmail: string) {
    const auth = getAuth();
    sendPasswordResetEmail(auth, passwordResetEmail)
      .then(() => {
        this.analyticsService.logEvent(
          this.analyticsService.events.forgotPassword
        );
        window.alert(
          'Si el email ingresado corresponde a un usuario registrado en nuestra plataforma, te debería llegar un correo para generar una nueva contraseña'
        );
      })
      .catch((error) => this.ShowError(error.code));
  }

  async SignOut() {
    const auth = getAuth();
    await signOut(auth);
    await this.router.navigate(['sign-in']);
    this.analyticsService.logEvent(this.analyticsService.events.logout);
  }

  async twoFactorHandling(error: any) {
    const auth = getAuth();
    const resolver = getMultiFactorResolver(auth, error);
    const { verificationId } = await this.sendTwoFactorSMS(resolver);
    return { verificationId, resolver };
  }

  async sendTwoFactorSMS(
    resolver: MultiFactorResolver
  ): Promise<{ verificationId: string }> {
    const auth = getAuth();
    const multiFactorHints = resolver.hints;
    const phoneInfoOptions: PhoneInfoOptions = {
      multiFactorHint: multiFactorHints[0],
      session: resolver.session,
    };

    const applicationVerifier = new RecaptchaVerifier(
      'recaptcha-container',
      { size: 'invisible' },
      auth
    );
    const provider = new PhoneAuthProvider(auth);

    const verificationId = await provider.verifyPhoneNumber(
      phoneInfoOptions,
      applicationVerifier
    );
    return { verificationId };
  }

  async verifyTwoFactorCode({
    verificationId,
    verificationCode,
    resolver,
  }: {
    verificationId: string;
    verificationCode: string;
    resolver: MultiFactorResolver;
  }) {
    const credential = PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    const multiFactorAssertion =
      PhoneMultiFactorGenerator.assertion(credential);

    return await resolver.resolveSignIn(multiFactorAssertion);
  }
}

export const environment = {
  firebase: {
    apiKey: 'AIzaSyCwCCs4D757w8xIR1OjeyG3Lrsvr0Pa9RU',
    authDomain: 'vagus-tech-development.firebaseapp.com',
    projectId: 'vagus-tech-development',
    storageBucket: 'vagus-tech-development.appspot.com',
    messagingSenderId: '443348713624',
    appId: '1:443348713624:web:cf44a50db77f4ae20010ed',
    measurementId: 'G-NYE01P5BNE',
    rechaptchaToken: '6LdHqV0oAAAAAGZsYFItQVtkiK6uNslrHOKOS8r4',
    appCheckDebugToken: false,
  },
  version: require('../../package.json').version,
  production: true,
  useEmulators: false,
  name: 'development',
  sentryDsn:
    'https://c08cbe6a919141cb817b8b1718d5dcbb@o4505522962759680.ingest.sentry.io/4505524669972480',
  apiUrl: 'https://dev.finapsys.cl/api',
};

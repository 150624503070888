import { Component, OnInit } from '@angular/core';
import {
  AppCheck,
  getToken,
  AppCheckTokenResult,
} from '@angular/fire/app-check';
import { Observable, from, share, EMPTY } from 'rxjs';
import { traceUntilFirst } from '@angular/fire/performance';
import { keepUnstableUntilFirst } from '@angular/fire';

@Component({
  selector: 'app-app-check',
  templateUrl: './app-check.component.html',
  styleUrls: ['./app-check.component.scss'],
})
export class AppCheckComponent implements OnInit {
  readonly change$: Observable<any>;

  constructor(appCheck: AppCheck) {
    if (appCheck) {
      this.change$ = from(getToken(appCheck)).pipe(
        traceUntilFirst('app-check'),
        keepUnstableUntilFirst,
        share()
      );
    } else {
      this.change$ = EMPTY;
    }
  }

  ngOnInit(): void {}
}
